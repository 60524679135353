import { Type } from "@app/types/enums";
import CreateTime from "@components/ArticleMeta/CreateTime";
import SectionLink from "@components/ArticleMeta/SectionLink";
import SubscriberFlag from "@components/ArticleMeta/SubscriberFlag";
import Summary from "@components/ArticleMeta/Summary";
import TitleLink from "@components/ArticleMeta/TitleLink";
import UpdateTime from "@components/ArticleMeta/UpdateTime";
import ArticleThumbnail from "@components/ArticleThumbnail/ArticleThumbnail";
import BylineForCards from "@components/Byline/BylineForCards";
import { faCircle, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn, getDefaultImage } from "@util/helpers";
import cx from "classnames";

import { BasicCardProps } from "./types";

export default function BasicCard({
  rootClassName = "",
  id,
  media,
  kicker = "",
  section,
  title = "",
  paywall = false,
  blurb = "",
  slug = "",
  edited = "",
  updated = "",
  hasUpdatedTimestampDisplay = false,
  variations,
  author = [],
  defaultImage,
  contributorProfile = [],
  h1Tag,
  isTitleLinkRequired = true,
}: BasicCardProps): React.ReactElement {
  const cardWrapperClass = [rootClassName];
  const imageWrapperClass = [];
  const contentWrapperClass = [
    variations?.content?.bgColor,
    variations?.content?.width,
  ];

  const _media = media && "length" in media ? media.at(0) : media;

  const mediaContent =
    _media &&
    (_media.content?.type === "picture" ||
      _media.content?.type === Type.ArticleBrightcoveVideo ||
      _media.content?.type === Type.ArticleYoutubeVideo)
      ? _media.content
      : undefined;

  const isVideo =
    _media &&
    (_media.content?.type === Type.ArticleBrightcoveVideo ||
      _media.content?.type === Type.ArticleYoutubeVideo);

  const thumbnail =
    _media && mediaContent
      ? mediaContent
        ? { ...mediaContent, alt: title }
        : { ..._media, alt: title }
      : undefined;

  const defaultThumbnail = defaultImage
    ? {
        url: getDefaultImage(defaultImage?.directoryName),
        alt: title,
      }
    : undefined;

  const cardThumbnail = thumbnail || defaultThumbnail;

  switch (variations?.image?.position) {
    case "left":
      imageWrapperClass.push("order-1 flex-shrink-0");
      contentWrapperClass.push("order-2");
      break;

    case "right":
      imageWrapperClass.push("order-2 flex-shrink-0");
      contentWrapperClass.push("order-1");
      break;
  }

  return (
    <div
      data-testid="basic-card"
      data-cueid={id}
      className={cn("relative flex flex-wrap items-start", cardWrapperClass)}
    >
      {cardThumbnail ? (
        <div
          className={cx(
            "relative flex",
            imageWrapperClass,
            variations?.image?.extraClass,
            variations?.image?.width
          )}
        >
          <ArticleThumbnail
            thumbnail={cardThumbnail}
            orientation="landscape"
            imgRootClassName={cx(
              "w-full",
              variations?.image?.aspectRatio || "aspect-3x2"
            )}
            filters={variations?.image?.filters}
            rootClassName="flex items-center justify-center bg-gray-250 w-full"
            invisibleImgRootClass={cx(
              "w-full",
              variations?.image?.invisibleImgRootClass
            )}
            fetchPriority={variations?.image?.fetchPriority}
          />

          {variations?.image?.isVideo || isVideo ? (
            <div
              className={
                variations?.image?.playIconPosition === "lower-left"
                  ? "fa-stack absolute bottom-3 left-2"
                  : ""
              }
            >
              <FontAwesomeIcon
                icon={faCircle}
                inverse
                className="fa-stack-2x"
              />
              <FontAwesomeIcon icon={faPlay} className="fa-stack-1x" />
            </div>
          ) : null}
        </div>
      ) : null}

      <div
        className={cx(
          "flex flex-grow flex-col",
          contentWrapperClass,
          variations?.content?.extraClass,
          {
            "p-4 text-white": variations?.content?.bgColor,
          }
        )}
      >
        {section ? (
          <SectionLink
            sectionName={section.name}
            sectionUniqueName={section.uniqueName}
            rootClassName={variations?.section?.extraClass}
          />
        ) : null}

        {kicker ? (
          <div
            data-testid="basic-card-kicker-component"
            className={cx(
              "break-words font-poppins text-4xs font-light",
              variations?.kicker?.color,
              variations?.kicker?.extraClass,
              {
                "text-white": variations?.content?.bgColor,
              }
            )}
          >
            {kicker.toUpperCase()}
          </div>
        ) : null}

        <TitleLink
          title={title}
          articleId={id}
          slug={slug}
          rootClassName={cn(
            variations?.title?.size,
            variations?.title?.extraClass
          )}
          h1Tag={h1Tag}
          linkRootClassName={variations?.title?.color}
          isTitleLinkRequired={isTitleLinkRequired}
        />

        {paywall ? (
          <SubscriberFlag
            rootClassName={variations?.subscriberLabel?.extraClass}
          />
        ) : null}

        {blurb ? (
          <Summary
            rootClassName={cx(
              "font-public-sans text-xs font-normal",
              variations?.content?.blurb?.extraClass,
              { "text-white": variations?.content?.bgColor }
            )}
            summary={blurb}
          />
        ) : null}

        {updated || (edited && hasUpdatedTimestampDisplay) ? (
          <div className="mt-auto">
            {updated ? (
              <CreateTime
                created={updated}
                rootClassName={cx(variations?.content?.updatedTimeExtraClass)}
              />
            ) : null}

            {edited && hasUpdatedTimestampDisplay ? (
              <UpdateTime
                updated={updated}
                edited={edited}
                rootClassName={cx(
                  "text-red",
                  variations?.content?.updatedTimeColor
                )}
              />
            ) : null}
          </div>
        ) : null}

        {contributorProfile.length > 0 || author.length > 0 ? (
          <BylineForCards
            authors={author}
            contributors={contributorProfile}
            imageWidth={variations?.byline?.imageWidth}
            imageHeight={variations?.byline?.imageHeight}
            size={variations?.byline?.size}
            rootClassName={cx("mt-2", variations?.byline?.extraClass)}
          />
        ) : null}
      </div>
    </div>
  );
}
