import { useEffect, useState } from "react";

import {
  SCREEN_LG,
  SCREEN_MD,
  SCREEN_SM,
  SCREEN_XL,
  SCREEN_XS,
} from "../util/constant";

/**
 * @returns {object} The current window size state.
 */
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  const isScreenXL = windowSize?.width >= SCREEN_XL;
  const isScreenLG = windowSize?.width >= SCREEN_LG;
  const isScreenMD = windowSize?.width >= SCREEN_MD;
  const isScreenSM = windowSize?.width >= SCREEN_SM;
  const isScreenXS = windowSize?.width >= SCREEN_XS;
  const isScreenBelowXS = windowSize?.width < SCREEN_XS;

  useEffect(() => {
    // useEffect is only called client-side.
    // To prevent SSR issues, we check if window is defined.
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      handleResize();
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    isScreenXL,
    isScreenLG,
    isScreenMD,
    isScreenSM,
    isScreenXS,
    isScreenBelowXS,
  };
}

export { useWindowSize };
