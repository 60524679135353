import { ReactElement } from "react";
import type { ArticleDataObject } from "@app/types/Cue";
import { Vertical } from "@app/types/enums";
import { LatestStoriesRowProps } from "@app/types/Verticals";
import { BlockTitle } from "@blocks/Blocks";
import VerticalLatest from "@components/ArticleCard/VerticalLatest";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import SeeMore from "@components/SeeMore/SeeMore";
import { getKickerFromArticleDataObject } from "@util/helpers";
import cx from "classnames";

import MostReadStories from "./MostReadStories";

export default function LatestStoriesRow({
  testId,
  seeMoreLink,
  verticalName,
  articles,
  mostReadStories,
}: LatestStoriesRowProps): ReactElement {
  const blockTitle = verticalName === Vertical.Sme ? "Latest" : "LATEST";

  return (
    <>
      {articles.length > 0 || mostReadStories.length > 0 ? (
        <Row rootClassName="mb-12" data-testid={testId}>
          <Column rootClassName={cx("w-full", "lg:w-8/12")}>
            <BlockTitle
              text={blockTitle}
              rootClassName="vertical-block-title mb-5 !font-poppins font-semibold !text-xl"
            />

            <>
              {articles?.map((article: ArticleDataObject, index: number) => {
                return (
                  <VerticalLatest
                    articleData={article}
                    rootClassName="mb-4 pb-4 border-b border-gray-250"
                    key={index}
                    verticalName={verticalName}
                    kicker={
                      article?.kicker?.fields.at(0)?.value ||
                      getKickerFromArticleDataObject(article)
                    }
                  />
                );
              })}
            </>

            <SeeMore verticalName={verticalName} link={seeMoreLink} />
          </Column>

          <Column rootClassName={cx("w-full", "lg:w-4/12")}>
            <MostReadStories
              articles={mostReadStories}
              verticalName={verticalName}
            />
          </Column>
        </Row>
      ) : null}
    </>
  );
}
