import { RouteFactory } from "@app/routePaths";
import { AdvertisementTypeEnum } from "@app/types/enums";
import SMELogo from "@assets/logo-sme.svg";
import { AdTargetingType } from "@components/Advertisement/AdUnit";
import Advertisement from "@components/Advertisement/Advertisement";
import SectionMasthead from "@components/SectionMasthead/SectionMasthead";
import { GoogleAdsSlotFactory } from "@util/helpers";

export default function SMEBanner({
  slotTargettings = [],
}: {
  slotTargettings?: AdTargetingType[];
}): React.ReactElement {
  return (
    <>
      <div className="flex w-full flex-wrap items-center justify-center gap-2 pb-2 md:justify-between">
        <SectionMasthead
          link={RouteFactory.sgsme}
          masthead={{
            alt: "SGSME logo",
            src: SMELogo,
            width: 126,
            height: 31,
          }}
        />
      </div>
      <div className=" block h-[1px] w-full bg-gray-850 "></div>

      <Advertisement
        rootClassName="py-6"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(RouteFactory.sgsme.replace("/", "")),
          slotTargettings: slotTargettings,
          adsClassName: "min-h-[90px]",
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
          slotTargettings: slotTargettings,
        }}
      />
    </>
  );
}
