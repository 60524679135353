import { useEffect, useState } from "react";
import { GrapeshotDataObject, GrapeshotResponse } from "@app/types/Cue";
import axios from "axios";

const fetchGrapeshotData = async (
  urlPath: string
): Promise<GrapeshotDataObject[]> => {
  try {
    const response = await axios.post<GrapeshotResponse>(
      "/_plat/api/v1/grapeshot",
      { urlPath: urlPath }
    );

    return response.data?.channels || [];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `fetchGrapeshotData error for urlPath: ${urlPath}. Details: ${(error as Error).message}`
    ); // eslint-disable-line no-console
    return [];
  }
};

export const useGrapeshotData = (urlPath: string) => {
  const [gsChannels, setGsChannels] = useState<GrapeshotDataObject[]>([]);
  const gsChannelsNames = gsChannels.map((item) => item.name);

  useEffect(() => {
    const callFetch = async () => {
      setGsChannels(await fetchGrapeshotData(urlPath));
    };

    callFetch();
  }, [urlPath]);

  return { gsChannels, gsChannelsNames };
};
