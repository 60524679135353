import { Tag } from "@app/types/Cue";
import elevenThumbnail from "@assets/mybt/illustrations/7-eleven.png";
import aseanThumbnail from "@assets/mybt/illustrations/asean.png";
import ausieThumbnail from "@assets/mybt/illustrations/australia.png";
import aussieSThumbnail from "@assets/mybt/illustrations/australia-new-zealand-stocks.png";
import bankThumbnail from "@assets/mybt/illustrations/bank-of-singapore.png";
import bondsThumbnail from "@assets/mybt/illustrations/bonds.png";
import canadaThumbnail from "@assets/mybt/illustrations/canada.png";
import chinaThumbnail from "@assets/mybt/illustrations/china.png";
import chinaEThumbnail from "@assets/mybt/illustrations/china-economy.png";
import chinaSThumbnail from "@assets/mybt/illustrations/china-stocks.png";
import climateThumbnail from "@assets/mybt/illustrations/climate-change.png";
import covidThumbnail from "@assets/mybt/illustrations/covid-19.png";
import cryptoThumbnail from "@assets/mybt/illustrations/cryptocurrencies.png";
import currenciesThumbnail from "@assets/mybt/illustrations/currencies.png";
import electricThumbnail from "@assets/mybt/illustrations/electric-vehicles.png";
import europeSThumbnail from "@assets/mybt/illustrations/europe-stocks.png";
import europeanThumbnail from "@assets/mybt/illustrations/european-union.png";
import financialResultThumbnail from "@assets/mybt/illustrations/financial-results.png";
import hkThumbnail from "@assets/mybt/illustrations/hong-kong.png";
import HKSThumbnail from "@assets/mybt/illustrations/hong-kong-stocks.png";
import housingThumbnail from "@assets/mybt/illustrations/housing.png";
import indiaThumbnail from "@assets/mybt/illustrations/india.png";
import indo1Thumbnail from "@assets/mybt/illustrations/indonesia (1).png";
import indonesiaThumbnail from "@assets/mybt/illustrations/indonesia.png";
import inflationThumbnail from "@assets/mybt/illustrations/inflation.png";
import interestThumbnail from "@assets/mybt/illustrations/interest-rates.png";
import japanThumbnail from "@assets/mybt/illustrations/japan.png";
import japanSThumbnail from "@assets/mybt/illustrations/japan-stocks.png";
import maThumbnail from "@assets/mybt/illustrations/m-a.png";
import malaysia1Thumbnail from "@assets/mybt/illustrations/malaysia (1).png";
import malaysiaThumbnail from "@assets/mybt/illustrations/malaysia.png";
import malayiaSThumbnail from "@assets/mybt/illustrations/malaysia-stocks.png";
import metalsThumbnail from "@assets/mybt/illustrations/metals.png";
import oilThumbnail from "@assets/mybt/illustrations/oil-prices.png";
import opecThumbnail from "@assets/mybt/illustrations/opec.png";
import russ1Thumbnail from "@assets/mybt/illustrations/russia (1).png";
import russiaThumbnail from "@assets/mybt/illustrations/russia.png";
import semiThumbnail from "@assets/mybt/illustrations/semiconductors.png";
import singaporeEThumbnail from "@assets/mybt/illustrations/singapore-economy.png";
import singaporeSThumbnail from "@assets/mybt/illustrations/singapore-stocks.png";
import sokorThumbnail from "@assets/mybt/illustrations/south-korea.png";
import straitsSThumbnail from "@assets/mybt/illustrations/straits-times-index.png";
import sustainabilityThumbnail from "@assets/mybt/illustrations/sustainability.png";
import thailandThumbnail from "@assets/mybt/illustrations/thailand.png";
import UKEThumbnail from "@assets/mybt/illustrations/uk-economy.png";
import UKThumbnail from "@assets/mybt/illustrations/united-kingdom.png";
import USThumbnail from "@assets/mybt/illustrations/united-states.png";
import USEThumbnail from "@assets/mybt/illustrations/us-economy.png";
import usThumbnail from "@assets/mybt/illustrations/us-federal-reserve.png";
import USSThumbnail from "@assets/mybt/illustrations/us-stocks.png";
import yenThumbnail from "@assets/mybt/illustrations/yen.png";

export const myBTRecommendedKeywords: Tag[] = [
  {
    name: "Financial Results",
    uri: "main/financial-results",
    thumbnail: financialResultThumbnail,
  },
  { name: "Inflation", uri: "main/inflation", thumbnail: inflationThumbnail },
  {
    name: "Cryptocurrencies",
    uri: "main/cryptocurrencies",
    thumbnail: cryptoThumbnail,
  },
  { name: "Covid-19", uri: "main/covid-19", thumbnail: covidThumbnail },
  {
    name: "Interest Rates",
    uri: "main/interest-rates",
    thumbnail: interestThumbnail,
  },
  {
    name: "Electric Vehicles",
    uri: "main/electric-vehicles",
    thumbnail: electricThumbnail,
  },
  { name: "Canada", uri: "main/canada", thumbnail: canadaThumbnail },
  {
    name: "Climate Change",
    uri: "main/climate-change",
    thumbnail: climateThumbnail,
  },
  {
    name: "Currencies",
    uri: "main/currencies",
    thumbnail: currenciesThumbnail,
  },
  {
    name: "Semiconductors",
    uri: "main/semiconductors",
    thumbnail: semiThumbnail,
  },
  { name: "Housing", uri: "main/housing", thumbnail: housingThumbnail },
  { name: "M&A", uri: "main/m-a", thumbnail: maThumbnail },
  { name: "Bonds", uri: "main/bonds", thumbnail: bondsThumbnail },
  {
    name: "Sustainability",
    uri: "main/sustainability",
    thumbnail: sustainabilityThumbnail,
  },
  { name: "Oil Prices", uri: "main/oil-prices", thumbnail: oilThumbnail },
  {
    name: "US Federal Reserve",
    uri: "main/us-federal-reserve",
    thumbnail: usThumbnail,
  },
  { name: "Opec", uri: "main/opec", thumbnail: opecThumbnail },
  { name: "Russia", uri: "main/russia", thumbnail: russiaThumbnail },
  { name: "Metals", uri: "main/metals", thumbnail: metalsThumbnail },
  { name: "Yen", uri: "main/yen", thumbnail: yenThumbnail },
  { name: "Malaysia", uri: "main/malaysia", thumbnail: malaysiaThumbnail },
  { name: "7-eleven", uri: "main/7-eleven", thumbnail: elevenThumbnail },
] as Tag[];

export const myBTRegionsKeywords: Tag[] = [
  {
    name: "China",
    uri: "main/china",
    thumbnail: chinaThumbnail,
  },
  {
    name: "United States",
    uri: "main/united-states",
    thumbnail: USThumbnail,
  },
  {
    name: "United Kingdom",
    uri: "main/united-kingdom",
    thumbnail: UKThumbnail,
  },
  {
    name: "US Economy",
    uri: "main/us-economy",
    thumbnail: USEThumbnail,
  },
  {
    name: "Japan",
    uri: "main/japan",
    thumbnail: japanThumbnail,
  },
  {
    name: "Bank of Singapore",
    uri: "main/bank-of-singapore",
    thumbnail: bankThumbnail,
  },
  {
    name: "Australia",
    uri: "main/australia",
    thumbnail: ausieThumbnail,
  },
  {
    name: "South Korea",
    uri: "main/south-korea",
    thumbnail: sokorThumbnail,
  },
  {
    name: "Indonesia",
    uri: "main/indonesia",
    thumbnail: indo1Thumbnail,
  },
  {
    name: "Hong Kong",
    uri: "main/hong-kong",
    thumbnail: hkThumbnail,
  },
  {
    name: "India",
    uri: "main/india",
    thumbnail: indiaThumbnail,
  },
  {
    name: "Russia",
    uri: "main/russia",
    thumbnail: russ1Thumbnail,
  },
  {
    name: "China Economy",
    uri: "main/china-economy",
    thumbnail: chinaEThumbnail,
  },
  {
    name: "UK Economy",
    uri: "main/uk-economy",
    thumbnail: UKEThumbnail,
  },
  {
    name: "Malaysia",
    uri: "main/malaysia",
    thumbnail: malaysia1Thumbnail,
  },
  {
    name: "European Union",
    uri: "main/european-union",
    thumbnail: europeanThumbnail,
  },
  {
    name: "Thailand",
    uri: "main/thailand",
    thumbnail: thailandThumbnail,
  },
  {
    name: "Asean",
    uri: "main/asean",
    thumbnail: aseanThumbnail,
  },
  {
    name: "Singapore Economy",
    uri: "main/singapore-economy",
    thumbnail: singaporeEThumbnail,
  },
] as Tag[];

export const myBTStocksKeywords: Tag[] = [
  {
    name: "China stocks",
    uri: "main/china-stocks",
    thumbnail: chinaSThumbnail,
  },
  {
    name: "Singapore Stocks",
    uri: "main/singapore-stocks",
    thumbnail: singaporeSThumbnail,
  },
  {
    name: "Hong Kong stocks",
    uri: "main/hong-kong-stocks",
    thumbnail: HKSThumbnail,
  },
  {
    name: "Europe Stocks",
    uri: "main/europe-stocks",
    thumbnail: europeSThumbnail,
  },
  {
    name: "Japan stocks",
    uri: "main/japan-stocks",
    thumbnail: japanSThumbnail,
  },
  {
    name: "Malaysia stocks",
    uri: "main/malaysia-stocks",
    thumbnail: malayiaSThumbnail,
  },
  {
    name: "Australia/New Zealand stocks",
    uri: "main/australia-new-zealand-stocks",
    thumbnail: aussieSThumbnail,
  },
  {
    name: "US stocks",
    uri: "main/us-stocks",
    thumbnail: USSThumbnail,
  },
  {
    name: "Straits Times Index",
    uri: "main/straits-times-index",
    thumbnail: straitsSThumbnail,
  },
  {
    name: "Indonesia",
    uri: "main/indonesia",
    thumbnail: indonesiaThumbnail,
  },
] as Tag[];

export const myBTTagsDefinedList: {
  name: "recommended" | "stocks" | "regions";
  label: string;
  tags: Tag[];
}[] = [
  {
    name: "recommended",
    label: "Recommended",
    tags: myBTRecommendedKeywords,
  },
  {
    name: "stocks",
    label: "Stocks",
    tags: myBTStocksKeywords,
  },
  {
    name: "regions",
    label: "Region",
    tags: myBTRegionsKeywords,
  },
];
