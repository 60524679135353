/**
 * Company: SPHMedia
 * Description: Use LifestyleHero component as a sub-component of Lifestyle page layout
 */
import type { ArticleDataObject } from "@app/types/Cue";
import { CaaSImageFilters } from "@app/types/OptimisedImage";
import { getCardThumbnail } from "@components/ArticleCard/helpers/helpers";
import LifestyleCard from "@components/ArticleCard/LifestyleCard";
import { DefaultImage } from "@components/ArticleCard/types";
import ArticleThumbnail from "@components/ArticleThumbnail/ArticleThumbnail";

export type LifestyleHeroProps = {
  testId?: string | null;
  article: ArticleDataObject;
  defaultImage?: DefaultImage;
};

export default function LifestyleHero({
  testId = null,
  article,
  defaultImage,
}: LifestyleHeroProps): React.ReactElement {
  const { id, title, media, sections, paywall, kicker, blurb, displaySetting } =
    article;
  const cardThumbnail = getCardThumbnail(title, media, defaultImage);

  const lifestyleHeroImageFilter: CaaSImageFilters[] = [
    { breakpoint: "(min-width: 992px)", w: 1200, h: 800, dpr: 1, f: "webp" },
    { breakpoint: "(min-width: 768px)", w: 900, h: 600, dpr: 1, f: "webp" },
    { w: 600, h: 400, dpr: 1, f: "webp" },
  ];

  return (
    <div className="relative my-7" data-testid={testId}>
      {cardThumbnail ? (
        <div className="h-100 w-full">
          <ArticleThumbnail
            thumbnail={cardThumbnail}
            imgRootClassName="w-full"
            filters={lifestyleHeroImageFilter}
          />
        </div>
      ) : null}
      <div className="absolute inset-0 flex justify-center">
        <div className="container">
          <div className="mt-[6%] w-full xs:max-lg:pl-[7%] md:pl-[4%] lg:w-2/5 lg:pl-[1.5%]">
            <LifestyleCard
              id={id}
              title={displaySetting?.displayHeadline || title}
              media={media}
              kicker={kicker?.fields?.at(0)?.value}
              section={sections?.at(0)}
              paywall={paywall?.contentAccess === "1"}
              blurb={blurb}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
