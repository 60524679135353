import { useEffect, useState } from "react";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { smxPrebidScript } from "@components/Advertisement/constants";
import { adSlotSizes } from "@util/constant";
import betteradsHead2Script from "@util/customScripts/betteradsHead2Script";
import betteradsHeadScript from "@util/customScripts/betteradsHeadScript";
import betteradsScript from "@util/customScripts/betteradsScript";

import useScript from "./useScript";

export default function useLoadedTags() {
  const [isPrestitialEnabled, setIsPrestitialEnabled] = useState(true);
  const [topOverlayImpressions, setTopOverlayImpressions] = useState(0);
  const [topOverlayValidity, setTopOverlayValidity] = useState(2);
  const [isOutbrainEnabled, setIsOutbrainEnabled] = useState(true);

  useScript({
    id: "suid-script",
    src: "https://dsuwzj1tch87b.cloudfront.net/suid/suid.min.js",
    type: "text/javascript",
  });

  useScript({
    id: "smx-prebid-script",
    src: "https://adtag.sphdigital.com/tag/smx/prebid.js",
    type: "text/javascript",
  });

  useEffect(() => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
    window.adslots = window.adslots || [];

    googletag.cmd.push(function () {
      const dfpTagsObj = googletag
        .pubads()
        .getSlots()
        .map((_slot) => {
          const id = _slot.getSlotElementId().replaceAll("dfp-ad-", "");
          const adunitType = id.replaceAll(/_(.*)$/g, "");

          const size = Object.keys(adSlotSizes[adunitType] || {}).map((key) => {
            const sizes = adSlotSizes[adunitType]?.[parseInt(key)];

            return sizes?.toString().replaceAll(",", "x");
          });

          const loadedDfpTag = {
            [id]: {
              adunit: _slot.getAdUnitPath(),
              slotname: _slot.getSlotElementId(),
              size: size.join(","),
            },
          };

          return loadedDfpTag;
        });

      const loadedDfpTags: Partial<Record<AdvertisementTypeEnum, object>> = {};

      dfpTagsObj.forEach((item) => {
        const key = Object.keys(item).at(0) as AdvertisementTypeEnum;

        key && (loadedDfpTags[key] = item[key]);
      });

      // Load dfpTagsScript.
      const loadDfpTagsScriptId = "loadDfpTagsScript";

      if (
        !document.getElementById(loadDfpTagsScriptId) &&
        Object.keys(loadedDfpTags).length > 0
      ) {
        const loadDfpTagsScript = document.createElement("script");

        loadDfpTagsScript.id = loadDfpTagsScriptId;
        loadDfpTagsScript.innerHTML = `var loaded_dfp_tags = '${JSON.stringify(
          loadedDfpTags
        )}';`;
        loadDfpTagsScript.type = "text/javascript";
        document.body.appendChild(loadDfpTagsScript);
      }

      // Load smx_prebid.js
      const loadSmxPrebidScriptId = "loadSmxPrebidScript";

      if (
        !document.getElementById(loadSmxPrebidScriptId) &&
        Object.keys(loadedDfpTags).length > 0
      ) {
        const script = document.createElement("script");
        script.id = loadSmxPrebidScriptId;
        script.src = smxPrebidScript;
        script.async = true;
        script.onload = () => {
          if (typeof window === "undefined") return;

          setIsPrestitialEnabled(window.prestitial_enabled === 1);
          setTopOverlayImpressions(window.topOverlayImpressions);
          setTopOverlayValidity(window.overlay_validity);
          setIsOutbrainEnabled(window.outbrain_enable === 1);
        };
        document.head.appendChild(script);
      }

      // Load betteradsHeadScript.
      const loadBetteradsHeadScriptId = "betteradsHeadScript";

      if (
        !document.getElementById(loadBetteradsHeadScriptId) &&
        Object.keys(loadedDfpTags).length > 0
      ) {
        const loadBetteradsHeadScript = document.createElement("script");

        loadBetteradsHeadScript.id = loadBetteradsHeadScriptId;
        loadBetteradsHeadScript.innerHTML = betteradsHeadScript;
        loadBetteradsHeadScript.type = "text/javascript";
        document.body.appendChild(loadBetteradsHeadScript);
      }

      // Load betteradsScript.
      const loadBetteradsScriptId = "betteradsScript";

      if (
        !document.getElementById(loadBetteradsScriptId) &&
        Object.keys(loadedDfpTags).length > 0
      ) {
        const loadBetteradsScript = document.createElement("script");

        loadBetteradsScript.id = loadBetteradsScriptId;
        loadBetteradsScript.innerHTML = betteradsScript;
        loadBetteradsScript.type = "text/javascript";
        document.body.appendChild(loadBetteradsScript);
      }

      // Load betteradsHead2Script.
      const loadBetteradsHead2ScriptId = "betteradsHead2Script";

      if (
        !document.getElementById(loadBetteradsHead2ScriptId) &&
        Object.keys(loadedDfpTags).length > 0
      ) {
        const loadBetteradsHead2Script = document.createElement("script");

        loadBetteradsHead2Script.id = loadBetteradsHead2ScriptId;
        loadBetteradsHead2Script.innerHTML = betteradsHead2Script;
        loadBetteradsHead2Script.type = "text/javascript";
        document.body.appendChild(loadBetteradsHead2Script);
      }
    });
  }, []);

  return {
    isPrestitialEnabled,
    topOverlayImpressions,
    topOverlayValidity,
    isOutbrainEnabled,
  };
}
