import { Link } from "react-router-dom";
import cx from "classnames";

type SkyboxCardProps = {
  title: string;
  link: string;
  meta: string;
  image: string;
  blurb?: string;
};

export default function SkyboxCard({
  title,
  link,
  meta,
  image,
  blurb,
}: SkyboxCardProps): React.ReactElement {
  return (
    <div className="relative flex pr-4">
      <div className={cx("w-[120px] flex-shrink-0 flex-grow-0", "md:w-1/3")}>
        <picture>
          <img
            loading="lazy"
            src={image}
            height={200}
            width={200}
            className="aspect-square w-full"
          />
        </picture>
      </div>

      <div className={cx("flex flex-col pl-4 md:w-2/3")}>
        {meta ? (
          <div className="font-poppins text-4xs font-light uppercase tracking-[1px]">
            {meta}
          </div>
        ) : null}

        <h2 className="hover:none my-1 font-lct text-base font-bold text-gray-750">
          {title}
        </h2>

        {blurb ? (
          <div className="font-public-sans text-xs font-normal tracking-[1px] text-gray-750">
            {blurb}
          </div>
        ) : null}
      </div>

      <Link to={link} target="_blank" className={cx("absolute inset-0")}></Link>
    </div>
  );
}
