import { Link } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import { TestIdClassNameProps } from "@app/types/Common";
import { Tag } from "@app/types/Cue";
import { MyBTKeywordsDataList } from "@app/types/Page";
import iconForwardArrowBlack from "@assets/icon-forward-arrow-black.svg";
import SliderArrow from "@components/Slider/components/SliderArrow";
import { gaEventTracker } from "@util/helpers";
import cx from "classnames";

import MyBTCarouselSlide from "./MyBTCarouselSlide";

type MyBTCarouselProps = TestIdClassNameProps & {
  results: MyBTKeywordsDataList;
  keywords: Tag[];
  title: string;
  page: string;
  showTooltip: boolean;
  disablePaywall?: boolean;
  seeAlsoLink: string;
};

export default function MyBTHomeCarousel({
  testId,
  rootClassName,
  results,
  keywords,
  disablePaywall = false,
  seeAlsoLink,
}: MyBTCarouselProps): React.ReactElement {
  const SliderComponent =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    typeof window === "undefined" ? Slider.default : Slider;

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 4,
    className: "mybt-homepage-carousel -mx-3",
    arrows: true,
    prevArrow: (
      <SliderArrow
        direction="left"
        rootClassName="absolute bottom-[calc(100%-.2rem)] lg:bottom-full right-[4.5rem] md:block hidden"
        opacityWhenDisabled="opacity-30"
        prevArrowClass=""
      />
    ),
    nextArrow: (
      <SliderArrow
        direction="right"
        rootClassName="absolute bottom-[calc(100%-.2rem)] lg:bottom-full right-6 md:block hidden"
        opacityWhenDisabled="opacity-30"
        nextArrowClass=""
      />
    ),
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 2.5,
        },
      },
    ],
  };

  const handleSeeMore: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    const target = e.target as HTMLAnchorElement;
    const label = target.href ? `see more - ${target.href}` : "see more";
    gaEventTracker("mybt", "click", label);
  };

  return (
    <div className={rootClassName} data-testid={testId}>
      <h1
        className={cx(
          "font-poppins text-lg font-bold text-gray-850 lg:text-3xl"
        )}
      >
        <Link
          to={seeAlsoLink}
          className={cx("hover:underline")}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleSeeMore}
          aria-label="MYBT"
        >
          MYBT
          <img
            src={iconForwardArrowBlack}
            alt={"MYBT"}
            width="8"
            height="13"
            className="mb-1 ml-2 inline"
          />
        </Link>
      </h1>

      <SliderComponent {...settings}>
        {keywords.map((keyword) => {
          const keywordData = results[keyword.uri || ""];
          return keywordData?.body ? (
            <MyBTCarouselSlide
              key={keyword.uri}
              articles={keywordData.body}
              tag={keyword}
              rootClassName="m-3"
              disablePaywall={disablePaywall}
            />
          ) : null;
        })}
      </SliderComponent>
    </div>
  );
}
