import { useState } from "react";
import { TestIdClassNameProps } from "@app/types/Common";
import newsletterImage from "@assets/generic_newsletter.png";
import { EmarsysResponse } from "@components/Newsletter/types/Emarsys";
import { Newsletter } from "@components/Newsletter/types/Newsletter";
import { ArticleNewsletterForm } from "@pages/Article/components/ArticleNewsletter/ArticleNewsletterForm";
import { ArticleNewsletterFormSuccess } from "@pages/Article/components/ArticleNewsletter/ArticleNewsletterFormSuccess";
import { NewsletterFormTrigger } from "@pages/Article/components/ArticleNewsletter/NewsletterFormTrigger";
import { commonButtonCss } from "@util/constant";
import cx from "classnames";

type GenericNewsletterProps = TestIdClassNameProps & {
  newsletters: Newsletter[];
  displayNewVersionNewsletter?: boolean;
};
export default function GenericNewsletter({
  testId,
  rootClassName,
  newsletters,
  displayNewVersionNewsletter,
}: GenericNewsletterProps): React.ReactElement {
  return (
    <div
      className={cx(rootClassName)}
      data-testid={testId}
      data-component="generic-newsletter"
    >
      {displayNewVersionNewsletter ? (
        <GenericNewsletterVersion2 {...{ newsletters }} />
      ) : (
        <GenericNewsletterVersion1 {...{ newsletters }} />
      )}
    </div>
  );
}

function GenericNewsletterVersion1({
  newsletters,
}: GenericNewsletterProps): React.ReactElement {
  const [showForm, setShowForm] = useState(false);
  const [emarsysResponse, setEmarsysResponse] = useState<EmarsysResponse>();

  return (
    <>
      {emarsysResponse?.status !== "success" ? (
        <>
          <h5 className="pt-3 font-poppins text-lg font-semibold tracking-[1px] md:text-3xl">
            GET BT IN YOUR INBOX DAILY
          </h5>

          <div className="flex pb-8 pt-3">
            <img
              alt="Newsletter Img"
              className="hidden h-auto max-w-full self-start min-[321px]:block"
              width="75"
              height="75"
              src={newsletterImage}
            />
            <div className="min-[321px]:pl-4 md:pl-6">
              <p
                role="description"
                className="mb-3 font-public-sans text-base text-gray-850 md:text-lg"
              >
                Start and end each day with the latest news stories and analyses
                delivered straight to your inbox.
              </p>

              <div className="relative w-full md:w-4/5 lg:w-7/10 xl:w-6/10">
                {!showForm ? (
                  <NewsletterFormTrigger
                    setShowForm={setShowForm}
                    showViewAll
                  />
                ) : null}

                <ArticleNewsletterForm
                  showForm={showForm}
                  newsletters={newsletters}
                  emarsysResponse={emarsysResponse}
                  setEmarsysResponse={setEmarsysResponse}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}

      {emarsysResponse?.status === "success" ? (
        <ArticleNewsletterFormSuccess />
      ) : null}
    </>
  );
}

function GenericNewsletterVersion2({
  newsletters,
}: GenericNewsletterProps): React.ReactElement {
  const [showForm, setShowForm] = useState(false);
  const [emarsysResponse, setEmarsysResponse] = useState<EmarsysResponse>();

  return (
    <>
      {emarsysResponse?.status !== "success" ? (
        <>
          <h5 className="mb-6 border-b border-gray-850 pb-2 !font-poppins text-base font-semibold uppercase leading-normal tracking-[0.1rem] text-gray-850 md:text-lg md:tracking-[0.05625rem]">
            BT in your inbox
          </h5>
          <div className="flex py-3 md:px-10 md:py-6">
            <img
              alt="Newsletter Img"
              className="hidden h-auto max-w-full self-start min-[321px]:block"
              width="75"
              height="75"
              src={newsletterImage}
            />
            <div className="min-[321px]:pl-4 md:pl-6">
              <p
                role="description"
                className="mb-3 font-public-sans text-base font-light tracking-normal text-gray-850 md:text-lg"
              >
                Start and end each day with the latest news stories and analyses
                delivered straight to your inbox.
              </p>

              <div className="relative hidden w-full md:block md:w-4/5 lg:w-7/10 xl:w-6/10">
                {!showForm ? (
                  <NewsletterFormTrigger
                    setShowForm={setShowForm}
                    displayNewVersionNewsletter
                  />
                ) : null}

                <ArticleNewsletterForm
                  showForm={showForm}
                  newsletters={newsletters}
                  emarsysResponse={emarsysResponse}
                  setEmarsysResponse={setEmarsysResponse}
                />
              </div>
            </div>
          </div>
          <div className="relative block w-full md:hidden ">
            {!showForm ? (
              <NewsletterFormTrigger
                setShowForm={setShowForm}
                displayNewVersionNewsletter
                commonButtonCss={commonButtonCss}
              />
            ) : null}

            <ArticleNewsletterForm
              showForm={showForm}
              newsletters={newsletters}
              emarsysResponse={emarsysResponse}
              setEmarsysResponse={setEmarsysResponse}
            />
          </div>
        </>
      ) : null}

      {emarsysResponse?.status === "success" ? (
        <ArticleNewsletterFormSuccess />
      ) : null}
    </>
  );
}
