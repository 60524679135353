import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { AdvertisementTypeEnum } from "@app/types/enums";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Container from "@components/Container/Container";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import KeywordFilter from "@components/KeywordFilter";
import MetaTags from "@components/MetaTags/MetaTags";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { getFormattedTextForAds, getTermPath } from "@pages/Section/helpers";
import { PODCAST_KEYWORDS_DATA } from "@pages/Section/layouts/PodcastsPage/util/constants";
import type { SectionDefaultProps } from "@pages/Section/types";
import { GoogleAdsSlotFactory } from "@util/helpers";

import PodcastsBanner from "./components/PodcastsBanner";
import PodcastsOverview from "./components/PodcastsOverview";

export default function PodcastsKeywordLayout({
  context,
}: SectionDefaultProps): ReactElement {
  if (context.kind !== "keyword") {
    throw new Error("Invalid context kind for PodcastsKeywordLayout");
  }

  const {
    data: { entity, title, overview },
  } = context;

  const { pathname } = useLocation();

  const termName = entity.name;
  const termPath = getTermPath(entity) || pathname.replace("/", "");

  const podcastKeyword = PODCAST_KEYWORDS_DATA.find((data) => {
    return data.tag.uri?.replace("main/", "keywords/") === termPath;
  });

  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: [
        { key: "page", value: "listing" },
        { key: "bttags", value: getFormattedTextForAds(termName) },
      ],
    });

  return (
    <div className="w-full">
      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${termName} - Find ${termName} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="article"
        slug={`/${termPath}`}
      />

      <Advertisement
        rootClassName="min-h-[90px] bg-gray-125 py-1 md:py-6 border-y border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1("keywords"),
          adsClassName: "min-h-[90px]",
        }}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial("keywords")}
        {...{ isPrestitialEnabled, topOverlayImpressions, topOverlayValidity }}
      />

      <Header />

      <Container rootClassName="py-8">
        <Row>
          <Column rootClassName="w-full mb-10">
            <>
              <KeywordFilter
                tag={entity}
                rootClassName="bg-gray-250 p-3 inline-block mb-6"
                tagClassName="!text-gray-850"
              />

              {podcastKeyword ? <PodcastsBanner {...podcastKeyword} /> : null}
            </>
          </Column>

          <Column rootClassName="w-full lg:w-8/12 mb-6">
            <>
              {podcastKeyword ? (
                <PodcastsOverview
                  articles={overview}
                  tag={podcastKeyword.tag}
                />
              ) : null}
            </>
          </Column>

          <Column rootClassName="w-full lg:w-4/12 mb-10">
            <Advertisement
              rootClassName="lg:sticky lg:top-[120px]"
              adUnitProps={{
                type: AdvertisementTypeEnum.IMU2,
                slot: GoogleAdsSlotFactory.imu2("keywords"),
              }}
            />
          </Column>
        </Row>
      </Container>

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.CATFISH,
          slot: GoogleAdsSlotFactory.catfish("keywords"),
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </div>
  );
}
