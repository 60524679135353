import { Element, Storyline } from "@app/types/Cue";
import { Type } from "@app/types/enums";
import ArticleParagraphs from "@pages/Article/components/ArticleParagraphs";
import cx from "classnames";

export type BoxProps = {
  className?: string;
  elements: Element[];
  storyline: Storyline;
};

export default function Box({
  className,
  elements,
  storyline,
}: BoxProps): React.ReactElement {
  return (
    <div
      className={cx(
        "border border-gray-450 px-4 py-3 after:clear-both after:block",
        className
      )}
      data-story-element={Type.Box}
      data-testid="box-component"
    >
      <ArticleParagraphs
        {...{
          id: "",
          elements,
          isPremium: false,
          isBoxWrapped: true,
          storyline,
        }}
      />
    </div>
  );
}
