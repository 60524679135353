/**
 * Company: SPHMedia
 * Description: International Global Layout
 */

import { ReactElement } from "react";
import { RouteFactory } from "@app/routePaths";
import { VerticalProps } from "@app/types/Verticals";
import smeLogo from "@assets/logo-sme.svg";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Container from "@components/Container/Container";
import FooterVerticalBanner from "@components/Footer/FooterComponents/FooterVerticalBanner";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import NewsletterSignUpPanel from "@components/Newsletter/Verticals/NewsletterSignUp";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import KeywordStoriesRow from "@pages/Section/components/VerticalMeta/KeywordStoriesRow";
import LatestStoriesRow from "@pages/Section/components/VerticalMeta/LatestStoriesRow";
import TopStoriesRow from "@pages/Section/components/VerticalMeta/TopStoriesRow";
import { SGSME_DESCRIPTION } from "@pages/Section/layouts/Verticals/utils/constant";
import { GoogleAdsSlotFactory } from "@util/helpers";

import SMEBanner from "./components/SMEBanner";
import SMENewsletterDescription from "./components/SMENewsletterDescription";

export default function SMEVertical({
  context,
  mostReadArticles,
}: VerticalProps): ReactElement {
  const {
    data: { title, topStories, additional },
  } = context;
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: { key: "page", value: "listing" },
    });
  const singaporeRouteWithoutLeadingSlash = RouteFactory.sgsme.replace("/", "");

  return (
    <div className="w-full">
      <MetaTags
        title={title}
        description="THE BUSINESS TIMES SGSME - Find SGSME News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times."
        ogType="article"
        slug={RouteFactory.sgsme}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(
          singaporeRouteWithoutLeadingSlash
        )}
        {...{ isPrestitialEnabled, topOverlayImpressions, topOverlayValidity }}
      />

      <Header />

      <Container rootClassName="pt-6">
        <SMEBanner />
      </Container>

      <Container>
        <TopStoriesRow
          parentCategory={singaporeRouteWithoutLeadingSlash}
          articles={topStories}
          verticalName="sme"
        />

        <LatestStoriesRow
          articles={topStories.slice(4, 9)}
          seeMoreLink={`${RouteFactory.sgsme}/latest`}
          verticalName="sme"
          mostReadStories={mostReadArticles}
        />

        <KeywordStoriesRow
          seeMoreLink={RouteFactory.keywords("singapore-smes")}
          data={additional.at(0)}
          verticalName="sme"
          verticalBlockTitle="SINGAPORE SMES"
        />
      </Container>

      <NewsletterSignUpPanel
        rootClassName="mb-3"
        descriptionComponent={<SMENewsletterDescription />}
        newsletter={BT_NEWSLETTERS.filter(
          (newsletter) => newsletter.type === NEWSLETTER_TYPE.SGSME
        )}
        btnColors="bg-verticals-sme text-white hover:bg-white hover:text-verticals-sme"
        linkColors="text-verticals-sme"
      />

      <FooterVerticalBanner
        verticalImage={{ width: 220, height: 70, url: smeLogo, alt: "SGSME" }}
        verticalDescription={SGSME_DESCRIPTION}
      />
    </div>
  );
}
