import { useEffect, useState } from "react";
import type { DrupalSkyboxDataObject } from "@app/types/Drupal";
import * as Sentry from "@sentry/react";
import axios from "axios";

export type SkyboxResponse = {
  total: number;
  results: DrupalSkyboxDataObject[];
};

const fetchSkyboxArticles = async (): Promise<DrupalSkyboxDataObject[]> => {
  try {
    const response = await axios.get<SkyboxResponse>(
      "/_plat/api/v1/skybox-articles"
    );

    return response.data.results || [];
  } catch (e) {
    return [];
  }
};

export default function useSkyboxArticles(): DrupalSkyboxDataObject[] {
  const [data, setData] = useState<DrupalSkyboxDataObject[]>([]);

  useEffect(() => {
    const callFetch = async () => {
      setData(await fetchSkyboxArticles());
    };

    try {
      callFetch();
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  return data;
}
