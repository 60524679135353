import { PODCAST_KEYWORDS_DATA } from "@pages/Section/layouts/PodcastsPage/util/constants";

const excludedKeywords = [
  "main/garage-newsletter",
  "main/esg-insights",
  "main/thrive-newsletter",
  "main/property-insights",
  "main/asean-business-newsletter",
];

export const excludeTagJson = {
  nested: {
    path: "data.context.tags",
    query: {
      bool: {
        minimum_should_match: 1,
        should: [
          {
            terms: {
              "data.context.tags.uri.keyword": excludedKeywords,
            },
          },
        ],
      },
    },
  },
};

const excludedPodcastKeywords = PODCAST_KEYWORDS_DATA.map((data) => {
  return data.tag.uri;
});

const excludedPodcastKeywordsQuery = excludedPodcastKeywords.map((keyword) => {
  return {
    match_phrase: {
      "data.context.tags.uri": {
        query: keyword,
      },
    },
  };
});

export const excludedPodcastKeywordsJSON = {
  nested: {
    path: "data.context.tags",
    query: {
      bool: {
        minimum_should_match: 1,
        should: excludedPodcastKeywordsQuery,
      },
    },
  },
};

export const excludedKeywordsQuery = (keywordsToExclude: string[]) => {
  if (keywordsToExclude.length === 0) return;

  const excludeQuery = keywordsToExclude.map((keyword) => {
    return {
      match_phrase: {
        "data.context.tags.uri": {
          query: keyword,
        },
      },
    };
  });

  return {
    nested: {
      path: "data.context.tags",
      query: {
        bool: {
          minimum_should_match: 1,
          should: excludeQuery,
        },
      },
    },
  };
};

export const sortByStickyModifiedDate = [
  { "data.context.displaySetting.sticky": { order: "desc" } }, // Sticky articles first
  { "data.context.edited": { order: "desc" } }, // Sort by updated time in descending order
];

const excludePodcastsQuery = () => {
  const keywords = PODCAST_KEYWORDS_DATA.map((data) => {
    return data.tag;
  });

  return keywords.map((keyword) => {
    return {
      match_phrase: {
        "data.context.tags.uri": {
          query: keyword.uri?.replace("main/", ""),
        },
      },
    };
  });
};

export const excludePodcastsTagJson = {
  nested: {
    path: "data.context.tags",
    query: {
      bool: {
        minimum_should_match: 1,
        should: excludePodcastsQuery(),
      },
    },
  },
};

export const queryFilterByProfileId = (profileId: string) => {
  return {
    bool: {
      filter: {
        bool: {
          should: [
            {
              nested: {
                path: "data.context.authors.profiles",
                query: {
                  match_phrase: {
                    "data.context.authors.profiles.content.id": profileId,
                  },
                },
              },
            },
            {
              nested: {
                path: "data.context.relatedContributorProfile",
                query: {
                  match_phrase: {
                    "data.context.relatedContributorProfile.content.id":
                      profileId,
                  },
                },
              },
            },
          ],
        },
      },
    },
  };
};
