import { Link } from "react-router-dom";
import { cn } from "@util/helpers";

import { getIconSrc } from "./helpers";
import SocialEmbedButtonFactory from "./SocialMediaButtonFactory";
import { ActionType, ButtonProps, IconName, SocialMediaType } from "./types";

export default function Button({
  sizeClass = "h-[36px]",
  whichSocialMedia = SocialMediaType.facebook,
  shareUrl = "",
  actionType = ActionType.buttonLink,
  iconToDisplay = IconName.facebook,
  imgAlt = "icon",
  title = "",
}: ButtonProps) {
  const commonCss =
    "!border-gray-175 hover:!border-gray-850 !border-solid !border rounded-full transition-all duration-150 focus-within:outline-none focus:outline-none outline-none flex justify-center items-center flex justify-center items-center border-0 !outline-none";

  switch (actionType) {
    case ActionType.share:
      return (
        <SocialEmbedButtonFactory
          commonCss={commonCss}
          whichSocialMedia={whichSocialMedia}
          shareUrl={shareUrl}
          sizeClass={sizeClass}
          title={title}
        />
      );

    case ActionType.buttonLink:
    default:
      return (
        <Link
          className={cn(commonCss, sizeClass)}
          to={shareUrl}
          target="_blank"
          rel="noreferrer"
          referrerPolicy="strict-origin-when-cross-origin"
          title={iconToDisplay}
          data-testid={`link-button-${iconToDisplay}`}
          aria-label={iconToDisplay}
        >
          {iconToDisplay ? (
            <img
              src={getIconSrc(iconToDisplay)}
              width={24}
              height={24}
              alt={imgAlt}
            />
          ) : null}
        </Link>
      );
  }
}
