import { LambdaResponsePayload } from "@api/endpoint-handlers/bff-functions/Alacrity/types";
import {
  ARTICLE_SUBSHARE_BODY_TEXT,
  ARTICLE_SUBSHARE_EMAIL_SUBJ,
  GIFTER_SOCIALS,
} from "@pages/Article/components/ArticleSubshare/constants";

export default function Socials({
  data: { shortURL },
}: LambdaResponsePayload): React.ReactElement {
  return (
    <>
      {shortURL ? (
        <div
          className="mb-3 flex w-full justify-between border-b border-b-gray-275 pb-3"
          data-testid="gifter-socials-component"
        >
          {GIFTER_SOCIALS.map(({ label, icon, element }) => {
            const ShareButton = element;

            return (
              <ShareButton
                key={label}
                url={shortURL}
                title={ARTICLE_SUBSHARE_BODY_TEXT}
                subject={ARTICLE_SUBSHARE_EMAIL_SUBJ}
                body={ARTICLE_SUBSHARE_BODY_TEXT}
                className="max-w-[66px] flex-1 text-center"
              >
                <img
                  src={icon}
                  alt={label}
                  width={40}
                  height={40}
                  className="mx-auto aspect-square"
                />
                <span className="font-poppins text-4xs font-medium">
                  {label}
                </span>
              </ShareButton>
            );
          })}
        </div>
      ) : null}
    </>
  );
}
