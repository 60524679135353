import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import type { NavChildren, NavigationA } from "@components/Header/types";
import { useGetMainNavList } from "@hooks/useGetMainNavList";
import { cn } from "@util/helpers";
import cx from "classnames";

import { navigationConstant } from "./constant";

export type NavigationProps = {
  data?: NavigationA;
  position?: string;
};

export function Navigation({
  data,
  position,
}: NavigationProps): React.ReactElement {
  const navigationData = data?.length ? data : navigationConstant;
  const findMenuData = useGetMainNavList({ navigationData, position });

  return (
    <nav aria-label={`header-nav-${position}`} className="flex gap-3">
      {findMenuData?.map((item, index) => {
        return (
          <NavItem
            item={item}
            index={index}
            key={item.key}
            position={position}
          />
        );
      })}
    </nav>
  );
}

type NavItemProps = {
  item: NavChildren;
  position?: string;
  index?: number;
};

const navItemStyle = cx("p-3 duration-300", "hover:bg-gray-250 rounded-[25px]");

function NavItem({ item }: NavItemProps): React.ReactElement {
  const { label, link }: NavChildren = item;
  const [isInView, setInView] = useState(true);
  const { ref } = useInView({
    threshold: 1,
    onChange: (_, entry) => {
      setInView(entry?.intersectionRatio >= 1);
    },
  });

  return (
    <Link
      ref={ref}
      data-testid={`navigation-${label.toLowerCase()}`}
      role="link"
      to={`${link}?ref=header`}
      className={cn(
        "group invisible relative font-poppins text-2xs font-semibold leading-none tracking-tightest text-gray-850",
        navItemStyle,
        { visible: isInView }
      )}
      reloadDocument
    >
      {label}
    </Link>
  );
}
