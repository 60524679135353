import { HeroVariant, TagsVariant, Type } from "@app/types/enums";
import type { ArticleCardProps } from "@components/ArticleCard/types";
import KeywordLinks from "@components/ArticleMeta/KeywordLinks";
import SubscriberFlag from "@components/ArticleMeta/SubscriberFlag";
import TitleLink from "@components/ArticleMeta/TitleLink";
import UpdateTime from "@components/ArticleMeta/UpdateTime";
import { faCircle, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VERTICAL_COLOUR_VARIANTS, VerticalName } from "@util/constant";
import { getDefaultImage } from "@util/helpers";
import cx from "classnames";

import CreateTime from "../ArticleMeta/CreateTime";
import SectionLink from "../ArticleMeta/SectionLink";
import ArticleThumbnail from "../ArticleThumbnail/ArticleThumbnail";

export interface VerticalHeroProps extends ArticleCardProps {
  sectionLinkRootClassName?: string;
  verticalName: VerticalName;
  heroVariant: HeroVariant;
  showCreateTime?: boolean;
  tagsVariant: TagsVariant;
  sectionUniqueName: string;
  kicker?: string;
}

export default function VerticalHero({
  id = "",
  rootClassName = "",
  articleData,
  sectionLinkRootClassName = "",
  verticalName,
  heroVariant,
  showCreateTime = true,
  tagsVariant,
  sectionUniqueName,
  kicker = "",
}: VerticalHeroProps) {
  const {
    id: articleId,
    sections = [],
    title,
    edited,
    created,
    updated,
    media,
    displaySetting,
    tags = [],
    paywall,
    slug,
  } = articleData;

  const componentId = id || `article_${articleId}`;

  const _media = media && "length" in media ? media.at(0) : media;
  const thumbnail = _media?.content
    ? _media.content
    : {
        url: getDefaultImage(sectionUniqueName),
        alt: title,
      };

  const isVideo =
    _media &&
    (_media.content?.type === Type.ArticleBrightcoveVideo ||
      _media.content?.type === Type.ArticleYoutubeVideo);

  let tagsWrapperClass = "";
  let tagRootClassName = "";

  switch (tagsVariant) {
    case TagsVariant.Inside:
      tagsWrapperClass =
        "lg:absolute lg:bottom-0 lg:right-0 lg:flex-col lg:p-2 lg:text-white lg:gap-y-2";
      tagRootClassName = "lg:border-white";
      break;
    case TagsVariant.Outside:
      tagsWrapperClass = "lg:left-0 lg:top-full lg:mt-2 lg:absolute flex-wrap";
      break;
  }

  let titleLinkClass = "";

  switch (heroVariant) {
    case HeroVariant.Main:
      titleLinkClass = "text-xl lg:text-8xl";
      break;
    case HeroVariant.Secondary:
      titleLinkClass = "lg:text-md xl:text-xl";
      break;
  }

  return (
    <div
      id={componentId}
      data-testid={componentId}
      className={cx(
        "vertical-hero-test relative flex w-full gap-x-4",
        rootClassName
      )}
    >
      <div
        className={cx(
          "lg:w-full",
          heroVariant === HeroVariant.Main ? "w-4/10" : "w-120px"
        )}
      >
        {thumbnail ? (
          <div className={cx("relative")}>
            <ArticleThumbnail
              thumbnail={thumbnail}
              orientation="landscape"
              imgRootClassName="aspect-3x2 w-full"
            />
            {isVideo ? (
              <div>
                <FontAwesomeIcon
                  icon={faCircle}
                  inverse
                  className="fa-stack-2x"
                />
                <FontAwesomeIcon icon={faPlay} className="fa-stack-1x" />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div
        className={cx(
          heroVariant === HeroVariant.Main ? "w-6/10" : "w-full-120px",
          "flex flex-col",
          "lg:absolute lg:bottom-0 lg:left-0 lg:right-0 lg:order-2 lg:w-full lg:bg-gradient-to-b lg:from-transparent lg:to-black lg:p-4"
        )}
      >
        {sections && sections.length > 0 ? (
          <SectionLink
            rootClassName={cx(
              sectionLinkRootClassName,
              VERTICAL_COLOUR_VARIANTS[verticalName].textStyle,
              VERTICAL_COLOUR_VARIANTS[verticalName].linkStyle
            )}
            sectionName={sections.at(0)?.name}
            sectionUniqueName={sections.at(0)?.uniqueName}
          />
        ) : null}

        {kicker ? (
          <div
            className="break-words font-poppins text-4xs font-light lg:text-white"
            data-testid="vertical-hero-kicker-component"
          >
            {kicker}
          </div>
        ) : null}

        <TitleLink
          rootClassName={cx(
            "w-full font-semibold my-1 font-poppins",
            "lg:text-white lg:w-9/12",
            titleLinkClass
          )}
          title={displaySetting?.displayHeadline || title}
          articleId={articleId}
          slug={slug}
        />

        {paywall && paywall.contentAccess === "1" ? (
          <SubscriberFlag rootClassName="lg:text-white" />
        ) : null}
        {created ? (
          <div className="mt-auto flex gap-x-2">
            {showCreateTime && <CreateTime created={created} />}
            {displaySetting?.hasUpdatedTimeDisplayed ? (
              <UpdateTime
                updated={updated}
                edited={edited}
                rootClassName="text-red"
              />
            ) : null}
          </div>
        ) : null}
        {tags ? (
          <KeywordLinks
            tags={tags}
            rootClassName={tagsWrapperClass}
            tagRootClassName={tagRootClassName}
          />
        ) : null}
      </div>
    </div>
  );
}
