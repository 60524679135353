import { ArticleDataObject } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { useWindowSize } from "@hooks/useWindowSize";
import {
  getKickerFromArticleDataObject,
  isArticleUpdated,
  parseStoryThread,
} from "@util/helpers";
import cx from "classnames";

export default function HomePageTopStories({
  article,
  index,
  disablePaywall = false,
}: {
  article: ArticleDataObject;
  index: number;
  disablePaywall: boolean;
}): React.ReactElement {
  const { isScreenLG } = useWindowSize();
  const isLeadArticle = index === 0;
  const mainSection = article.sections?.at(0);
  const storyThread = parseStoryThread(article.others?.storyThread);

  const variant: CardVariations = {
    image: {
      position: index === 6 || index === 7 ? "left" : "",
      width: cx({
        "w-3/10": index === 6 || index === 7,
        "w-full": isLeadArticle,
      }),
      fetchPriority: isLeadArticle,
    },
    content: {
      width: cx({ "w-7/10": index === 6 || index === 7 }),
      bgColor: cx({ "bg-gray-850": isLeadArticle }),
      updatedTimeColor: isLeadArticle ? "!text-white" : "",
      extraClass: cx({
        "text-center items-center w-full": isLeadArticle,
        "pl-4": index === 6 || index === 7,
      }),
    },
    kicker: {
      color: "text-gray-515",
    },
    title: {
      size: isLeadArticle ? "text-4xl" : "",
      color: "text-gray-850",
    },
  };

  return (
    <div
      className={cx("story text- border-gray-175 lg:order-1 lg:col-span-1", {
        "order-1 lg:row-span-3": isLeadArticle,
        "lg:mb-0": index === 1 || index === 5 || index === 9,
        "lg:border-r lg:pr-3": index < 6,
        "order-2": index === 6,
        "order-3": index === 7,
        "order-4": (index >= 1 && index <= 5) || index > 7,
        "lg:pl-3": index > 1,
      })}
    >
      <BasicCard
        id={article.id}
        slug={article.slug}
        key={index}
        title={article.displaySetting?.displayHeadline || article.title}
        media={
          [6, 7].includes(index) || (isScreenLG && isLeadArticle)
            ? article.media
            : undefined
        }
        kicker={
          article.kicker?.fields?.[0]?.value ||
          getKickerFromArticleDataObject(article)
        }
        h1Tag={isLeadArticle}
        section={article.sections?.at(0)}
        storyThread={storyThread}
        paywall={
          !disablePaywall ? article.paywall?.contentAccess === "1" : false
        }
        variations={variant}
        rootClassName={cx("h-full border-b border-gray-175 pb-3 pt-3", {
          "lg:border-b-0 lg:pb-0": index === 1 || index === 5 || index === 9,
          "lg:pt-0": index === 0 || index === 2 || index === 6,
          "flex-col !flex-nowrap": isLeadArticle,
        })}
        edited={
          isArticleUpdated(article.updated, article.edited)
            ? article.edited
            : undefined
        }
        hasUpdatedTimestampDisplay={
          article.displaySetting?.hasUpdatedTimeDisplayed
        }
        defaultImage={
          mainSection &&
          ([6, 7].includes(index) || (isScreenLG && isLeadArticle))
            ? { directoryName: mainSection.uniqueName }
            : undefined
        }
      />
    </div>
  );
}
