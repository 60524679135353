import type { ArticleDataObject } from "@app/types/Cue";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { BlockTitle } from "@blocks/Blocks";
import Advertisement from "@components/Advertisement/Advertisement";
import BreakingNewsStories from "@pages/Home/BreakingNews/BreakingNewsStories";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import { GoogleAdsSlotFactory } from "@util/helpers";

export type BreakingNewsProps = {
  testId?: string | null;
  rootClassName?: string;
  data?: ArticleDataObject[];
};

const section = sectionNavigationItems["breaking-news"];

export default function BreakingNews({
  testId = null,
  rootClassName,
  data = [],
}: BreakingNewsProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  return (
    <section
      className={rootClassName}
      data-testid={testId}
      id="homepage-breakingnews-section"
    >
      <BlockTitle link={section?.link} text={section?.label?.toUpperCase()} />

      <div className="stories lg:grid lg:grid-cols-3">
        {data?.map((article: ArticleDataObject, index: number) => {
          return article ? (
            <BreakingNewsStories
              article={article}
              index={index}
              key={index}
              disablePaywall={
                OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
              }
            />
          ) : null;
        })}

        <div className="lg:order-2 lg:col-start-3 lg:row-span-2 lg:ml-3">
          <div className="h-full pb-3 pt-7 lg:pb-0 lg:pt-3">
            <Advertisement
              rootClassName="bg-gray-125 py-6 border-y border-gray-175 text-center -mx-3 md:mx-0"
              adUnitProps={{
                type: AdvertisementTypeEnum.IMU1,
                slot: GoogleAdsSlotFactory.imu1("homepage"),
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
