import { ImageProps } from "@app/types/Cue";
import btLogo from "@assets/bt-icon-black.svg";
import { Block } from "@blocks/Blocks";
import { FollowUsBT } from "@components/Footer/FooterComponents/FooterSubComponents";

export type FooterVerticalBannerProps = {
  verticalImage: ImageProps;
  verticalDescription: string;
};

export default function FooterVerticalBanner({
  verticalImage,
  verticalDescription,
}: FooterVerticalBannerProps): React.ReactElement {
  return (
    <Block rootClassName="bg-gray-250 py-6 w-full">
      <div className="container" data-testid="footer-subscribe-row">
        <div className="flex flex-wrap">
          <div className="w-full px-3 lg:w-6/12 ">
            <div className="flex justify-center lg:justify-start">
              <img
                width={verticalImage.width}
                height={verticalImage.height}
                src={verticalImage.url}
                alt={verticalImage.alt}
              />
            </div>

            <p className="fw-300 flex py-6 font-poppins text-base text-gray-750 lg:text-left">
              {verticalDescription}
            </p>
          </div>
          <div className="w-full px-3 lg:block lg:w-6/12">
            <FollowUsBT />
          </div>
          <div className="lg:w-12/12 mt-3 flex w-full flex-none flex-wrap px-3 lg:mt-0 lg:block lg:flex-nowrap">
            <div className="fw-700 pe-3 font-lato text-base font-semibold text-gray-900">
              Powered by
            </div>
            <div className="mt-1 lg:ps-1">
              <img
                width={122}
                height={12}
                src={btLogo}
                alt="The Business Times"
              />
            </div>
          </div>
        </div>
      </div>
    </Block>
  );
}
