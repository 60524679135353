import { Tag } from "@app/types/Cue";
import brandedPodcastThumbnail from "@assets/podcasts_banner/masthead-branded-podcasts.png";
import lensOnThumbnail from "@assets/podcasts_banner/masthead-lens-on.png";
import markToMarketThumbnail from "@assets/podcasts_banner/masthead-mark-to-market.png";
import marketFocusThumbnail from "@assets/podcasts_banner/masthead-market-focus.png";
import moneyHacksThumbnail from "@assets/podcasts_banner/masthead-money-hacks.png";
import propertyBTThumbnail from "@assets/podcasts_banner/masthead-propertybt.png";
import wealthBTThumbnail from "@assets/podcasts_banner/masthead-wealthbt.png";

export type PodcastKeywordData = {
  id:
    | "market_focus"
    | "money_hacks"
    | "lens_on"
    | "wealthbt"
    | "propertybt"
    | "mark_to_market"
    | "branded_podcasts";
  label: string;
  spotify_link: string;
  apple_link: string;
  channel_link: string;
  tag: Tag;
  description: string;
  thumbnailSrc: string;
};

export const PODCAST_KEYWORDS_DATA: PodcastKeywordData[] = [
  {
    id: "market_focus",
    label: "Market Focus",
    spotify_link: "https://bt.sg/mfsp",
    apple_link: "https://bt.sg/mfap",
    channel_link: "https://bt.sg/btmktfocus",
    tag: { name: "Market Focus", uri: "main/market-focus" } as Tag,
    description:
      "Market Focus from The Business Times is a look at market drivers and movements in Singapore, the region and beyond. Hosted by the BT Podcasts team presenting a daily closing bell update.",
    thumbnailSrc: marketFocusThumbnail,
  },
  {
    id: "money_hacks",
    label: "Money Hacks",
    spotify_link: "https://bt.sg/oeGN",
    apple_link: "https://bt.sg/oeXe",
    channel_link: "https://bt.sg/btmoneyhacks",
    tag: { name: "Money Hacks", uri: "main/money-hacks" } as Tag,
    description:
      "In this series, The Business Times breaks down actionable financial tips.",
    thumbnailSrc: moneyHacksThumbnail,
  },
  {
    id: "lens_on",
    label: "Lens On",
    spotify_link: "https://bt.sg/lenssp",
    apple_link: "https://bt.sg/lensap",
    channel_link: "https://bt.sg/btlenson",
    tag: { name: "Lens On", uri: "main/lens-on" } as Tag,
    description:
      "A monthly podcast series from The Business Times on current affairs, societal issues and government policies explored through the lens of how it impacts us in Singapore and the region.",
    thumbnailSrc: lensOnThumbnail,
  },
  {
    id: "wealthbt",
    label: "WealthBT",
    spotify_link: "http://bt.sg/wbSP",
    apple_link: "https://bt.sg/4DJp",
    channel_link: "https://bt.sg/btwealthbt",
    tag: { name: "WealthBT", uri: "main/wealthbt" } as Tag,
    description:
      "Learn to protect and grow your wealth in our monthly podcast series for affluent individuals, hosted by BT wealth editor Genevieve Cua.",
    thumbnailSrc: wealthBTThumbnail,
  },
  {
    id: "propertybt",
    label: "PropertyBT",
    spotify_link: "https://bt.sg/pbSP",
    apple_link: "https://bt.sg/pbAP",
    channel_link: "https://bt.sg/btpropertybt",
    tag: { name: "PropertyBT", uri: "main/propertybt" } as Tag,
    description:
      "Get insights from The Business Times on all things Singapore property, to help you in your property investment journey from senior correspondent Leslie Yee and industry players.",
    thumbnailSrc: propertyBTThumbnail,
  },
  {
    id: "mark_to_market",
    label: "Mark to Market",
    spotify_link: "https://bt.sg/4DJN",
    apple_link: "https://bt.sg/4DJp",
    channel_link: "https://bt.sg/btmark2mkt",
    tag: { name: "Mark to Market", uri: "main/mark-to-market" } as Tag,
    description:
      "In this series, The Business Times aims to provide analysis and insight on market trends and corporate issues in Singapore.",
    thumbnailSrc: markToMarketThumbnail,
  },
  {
    id: "branded_podcasts",
    label: "Branded Podcasts",
    spotify_link: "https://bt.sg/bpsp",
    apple_link: "https://bt.sg/bpap",
    channel_link: "https://bt.sg/btbrpod",
    tag: { name: "Branded Podcasts", uri: "main/branded-podcasts" } as Tag,
    description:
      "Finely curated, intelligent, thought-provoking branded content for decision-makers. Inspired by the newsroom of your trusted partner.",
    thumbnailSrc: brandedPodcastThumbnail,
  },
];
