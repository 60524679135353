import { RouteFactory } from "@app/routePaths";
import type { ArticleDataObject, Tag } from "@app/types/Cue";
import LoadMore from "@components/LoadMore/LoadMore";
import { useLoadMoreStoriesBySectionKeyword } from "@hooks/useLoadMoreStoriesByCategory";

import PodcastsListingStories from "./PodcastsListingStories";

export type PodcastsOverviewProps = {
  articles: ArticleDataObject[];
  tag: Tag;
};

export default function PodcastsOverview({
  articles = [],
  tag,
}: PodcastsOverviewProps): React.ReactElement {
  const podcastSection = RouteFactory.podcasts.replace("/", "");
  const podcastKeyword = tag.uri?.replace("main/", "") || "";

  const { articleList, handleLoadMore, hasMoreArticles } =
    useLoadMoreStoriesBySectionKeyword(
      podcastSection,
      podcastKeyword,
      articles,
      5
    );

  return (
    <section data-testid="podcasts-overview-component">
      <div className="stories">
        {articleList.map((article) => {
          return <PodcastsListingStories key={article.id} {...article} />;
        })}
      </div>

      {hasMoreArticles ? (
        <LoadMore
          rootClassName="my-4"
          onLoadMore={handleLoadMore}
          hasMore={hasMoreArticles}
        />
      ) : null}
    </section>
  );
}
