import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import logoMyBT from "@assets/logo-mybt-my.svg";
import Expire from "@components/Expire";
import cx from "classnames";

type FollowKeywordModalProps = {
  hasError: boolean;
};

export default function FollowKeywordModal({
  hasError,
}: FollowKeywordModalProps): React.ReactElement {
  return (
    <Expire delay={3000}>
      <div
        className={cx(
          "fixed bottom-0 left-1/2 z-10 w-[335px] max-w-full -translate-x-1/2 p-4",
          "sm:w-[557px]"
        )}
      >
        <div className="flex items-center overflow-hidden rounded-md bg-gray-850 shadow-lg">
          <div className="mr-auto flex items-center space-x-3 px-5 py-3">
            <img src={logoMyBT} width={37} height={25} alt="" />

            <h3
              className={cx("font-poppins text-4xs text-white", "sm:text-base")}
            >
              {hasError
                ? "Keyword failed to add to your list"
                : "Keyword added to your list"}
            </h3>
          </div>

          {!hasError ? (
            <Link
              to={RouteFactory.myBT}
              className={cx(
                "border-l border-gray-675 bg-white bg-opacity-0 px-5 py-3 text-4xs text-white opacity-100 transition-colors duration-300",
                "hover:bg-opacity-10",
                "sm:text-base"
              )}
              reloadDocument
            >
              View
            </Link>
          ) : null}
        </div>
      </div>
    </Expire>
  );
}
