import { type ImgHTMLAttributes, useCallback } from "react";
import type { ImageProps, MediaContent } from "@app/types/Cue";
import { CaaSImageFilters } from "@app/types/OptimisedImage";
import OptimisedImage from "@components/OptimisedImage";
import cx from "classnames";

export type ArticleThumbnailProps = {
  rootClassName?: string;
  imgRootClassName?: string;
  thumbnail?: (Partial<MediaContent & ImageProps> & { alt?: string }) | null;
  // @TODO Add square orientation (used in skybox).
  orientation?: "landscape" | "freecrop" | "original" | "portrait";
  filters?: CaaSImageFilters[];
  landscapeThumbnailRootClassName?: string;
  landscapeImgRootClassName?: string;
  invisibleImgRootClass?: string;
  displayBlurBg?: boolean;
  fetchPriority?: boolean;
};

export default function ArticleThumbnail({
  rootClassName = "",
  imgRootClassName = "",
  thumbnail = null,
  orientation = "original",
  filters,
  landscapeThumbnailRootClassName = "",
  landscapeImgRootClassName = "",
  invisibleImgRootClass = "",
  displayBlurBg = false,
  fetchPriority = false,
}: ArticleThumbnailProps): React.ReactElement {
  const getThumbnailData = useCallback(() => {
    // TODO this is legacy function to handle different thumbnail format
    // Ideally we should have a unified format for thumbnail
    if (thumbnail?.url) {
      return {
        [orientation]: thumbnail,
      };
    }

    if (thumbnail?.poster) {
      return thumbnail?.poster?.[0]?.content?.fields;
    }

    return thumbnail?.fields;
  }, [thumbnail, orientation]);

  const thumbnailData = getThumbnailData();

  const isImageLandscape = () => {
    const imgW =
      thumbnailData &&
      thumbnailData?.[`${orientation}-caas`] &&
      thumbnailData?.[`${orientation}-caas`]?.width
        ? thumbnailData?.[`${orientation}-caas`]?.width || 0
        : 0;
    const imgH =
      thumbnailData &&
      thumbnailData?.[`${orientation}-caas`] &&
      thumbnailData?.[`${orientation}-caas`]?.height
        ? thumbnailData?.[`${orientation}-caas`]?.height || 0
        : 0;

    return imgW > imgH;
  };

  const thumbnailRootClass =
    isImageLandscape() && landscapeThumbnailRootClassName !== ""
      ? landscapeThumbnailRootClassName
      : rootClassName;

  const thumbnailSrc =
    thumbnailData && thumbnailData?.[`${orientation}-caas`]
      ? thumbnailData?.[`${orientation}-caas`]?.url
      : thumbnailData?.[orientation]?.url;

  const imgHTMLProps: ImgHTMLAttributes<HTMLImageElement> = {
    src: thumbnailSrc,
    alt: thumbnailData?.caption ?? thumbnail?.alt,
    className:
      isImageLandscape() && landscapeImgRootClassName !== ""
        ? landscapeImgRootClassName
        : imgRootClassName,
    fetchPriority: fetchPriority ? "high" : undefined,
  };

  return (
    <div className={cx(thumbnailRootClass)}>
      {thumbnailData && thumbnailData[`${orientation}-caas`] ? (
        <div
          className={cx(
            isImageLandscape() && landscapeImgRootClassName !== ""
              ? landscapeImgRootClassName
              : invisibleImgRootClass
          )}
        >
          <OptimisedImage {...imgHTMLProps} filters={filters} />
        </div>
      ) : (
        <picture>
          <img {...imgHTMLProps} />
        </picture>
      )}

      {displayBlurBg && thumbnailData ? (
        <div
          style={{
            background: `center / cover no-repeat url(${thumbnailSrc})`,
          }}
          className="absolute top-0 z-[-1] h-full w-full overflow-hidden blur-[100px]
          "
        />
      ) : null}
    </div>
  );
}
