import { Link } from "react-router-dom";
import { NavigationKeysEnums } from "@app/types/enums";
import type { IndividualSection } from "@components/Header/types";
import cx from "classnames";

export interface IndividualSideMenu {
  menuItems: IndividualSection;
}

export default function IndividualSideMenuSection({
  menuItems,
}: IndividualSideMenu) {
  const {
    label: sectionName,
    key: sectionKey,
    children: items,
  } = menuItems.section;

  return (
    <>
      <div
        className={cx({
          "block lg:hidden":
            sectionKey === NavigationKeysEnums.hamburgerTop && sectionName,
        })}
      >
        {sectionName ? (
          <>
            <p className="m-0 mx-8 pb-2 text-xs font-semibold uppercase text-gray-350 lg:hidden">
              {sectionName}
            </p>
            <div className="mx-8 h-[1px] w-auto bg-gray-175 lg:hidden"></div>
          </>
        ) : null}
      </div>
      <div
        className={cx(
          {
            "block lg:hidden": sectionKey === NavigationKeysEnums.hamburgerTop,
            "pb-0 lg:pb-4":
              sectionKey === NavigationKeysEnums.hamburgerRecommends,
            "pb-4": sectionKey !== NavigationKeysEnums.hamburgerRecommends,
          },
          "grid grid-cols-2 gap-1 pt-1 lg:block lg:border-b lg:border-gray-175"
        )}
      >
        {items?.map((sideNavigationItem, index) => {
          const { key, link, label, children, mobileLabel } =
            sideNavigationItem;
          return (
            <div
              key={`side-menu-${index}`}
              id={`side-drawer-navigation-${key}`}
              data-testid={`side-drawer-navigation-${key}`}
              className={cx("group relative text-gray-850 hover:bg-gray-175", {
                "hidden lg:block":
                  sectionKey === NavigationKeysEnums.hamburgerRecommends &&
                  index === 0,
              })}
            >
              <Link
                className={cx(
                  {
                    "pl-8":
                      (index % 2 === 0 &&
                        sectionKey !==
                          NavigationKeysEnums.hamburgerRecommends) ||
                      (index % 2 !== 0 &&
                        sectionKey === NavigationKeysEnums.hamburgerRecommends),
                  },
                  {
                    "pr-8":
                      (index % 2 !== 0 &&
                        sectionKey !==
                          NavigationKeysEnums.hamburgerRecommends) ||
                      (index % 2 === 0 &&
                        sectionKey === NavigationKeysEnums.hamburgerRecommends),
                  },
                  "flex items-center justify-between py-3 font-poppins text-2xs font-semibold tracking-[0.01875rem] lg:py-2 lg:pl-5 lg:pr-4"
                )}
                to={`${link}?ref=hamburger-menu`}
                reloadDocument
                aria-label={label}
              >
                <span className="hidden lg:block">{label}</span>
                <span className="block lg:hidden">{mobileLabel || label}</span>
                {children?.length ? (
                  <svg
                    className="hidden h-[10px] w-[5px] fill-gray-350 group-hover:fill-gray-850 lg:block"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 5.50624L0.947059 11L0 10.1385L5.09216 5.50624L0 0.861521L0.947059 0L7 5.49376V5.50624Z" />
                  </svg>
                ) : null}
              </Link>

              {children?.length ? (
                <div
                  className={cx(
                    "invisible absolute left-full top-0 hidden w-max min-w-[160px] bg-white opacity-0 shadow-2xl duration-300 lg:block",
                    "group-hover:visible group-hover:opacity-100 group-hover:delay-100"
                  )}
                >
                  {children.map((obj, index) => {
                    return (
                      <Link
                        key={`${obj.key}${index}`}
                        className="block px-3 py-2 font-poppins text-2xs font-semibold hover:bg-gray-175"
                        to={`${obj.link}?ref=hamburger-menu`}
                        reloadDocument
                      >
                        {obj.label}
                      </Link>
                    );
                  })}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </>
  );
}
