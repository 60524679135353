import emailIcon from "@assets/icon-email.svg";
import facebookIcon from "@assets/icon-facebook.svg";
import instagramIcon from "@assets/icon-instagram.svg";
import linkedinIcon from "@assets/icon-linkedin.svg";
import telegramIcon from "@assets/icon-telegram.svg";
import twitterIcon from "@assets/icon-twitter.svg";
import whatsappIcon from "@assets/icon-whatsapp.svg";

import { IconName } from "./types";

export const getIconSrc = (icon: IconName) => {
  switch (icon) {
    case IconName.facebook:
      return facebookIcon;

    case IconName.telegram:
      return telegramIcon;

    case IconName.email:
      return emailIcon;

    case IconName.linkedin:
      return linkedinIcon;

    case IconName.twitter:
      return twitterIcon;

    case IconName.whatsapp:
      return whatsappIcon;

    case IconName.instagram:
      return instagramIcon;
  }
};
