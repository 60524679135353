/**
 * Company: SPHMedia
 * Description: Filter for keyword section page
 */

import { ReactElement, useState } from "react";
import { createPortal } from "react-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tag } from "@app/types/Cue";
import Button from "@components/AccessibleComponents/Button";
import FollowKeywordModal from "@components/FollowKeyword/FollowKeywordModal";
import { getKeywordLink } from "@components/FollowKeyword/helper";
import { faCheck, faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sectionRequests } from "@pages/Section/Section.server";
import { RouteFactory } from "@src/app/routePaths";
import { MYBT_REG_LIMIT, MYBT_SUB_LIMIT } from "@src/app/util/constant";
import useMyBtStore from "@store/useMyBtStore";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { cn, gaEventTracker, mySPHOpenLogin } from "@util/helpers";
import cx from "classnames";

import KeywordFilterTooltip from "./components/KeywordFilterTooltip";

export type KeywordFilterProps = {
  testId?: string;
  rootClassName?: string;
  tagClassName?: string;
  iconClassName?: string;
  tag: Tag;
  isFollowed?: boolean;
  disableTooltip?: boolean;
  disableBorder?: boolean;
  isLinkenabled?: boolean;
  followtextButton?: boolean;
  toolTipArrowClassName?: string;
  toolTipClassName?: string;
  withGiftIcon?: boolean;
};

enum HttpStatusCode {
  Success = 200,
  KeywordLimitReached = 429,
}

/**
 * KeywordFilter
 * Description: Display the keyword filter in the vertical section page
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function KeywordFilter({
  testId,
  tag,
  rootClassName,
  tagClassName,
  iconClassName,
  disableTooltip = false,
  disableBorder = false,
  isLinkenabled = true,
  followtextButton = false,
  toolTipArrowClassName,
  toolTipClassName,
  withGiftIcon,
}: KeywordFilterProps): ReactElement {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(false);

  const tagDisplayName = tag.name;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [userType, mysphw] = useOKTAUserStore((store) => [
    store.userInfo?.usertype,
    store.userInfo?.mysphw,
  ]);

  const [addTag, minusTag, followedTags, setIsLimitModalShown] = useMyBtStore(
    (store) => [
      store.followTag,
      store.unfollowTag,
      store.tags,
      store.setIsLimitModalShown,
    ]
  );

  const follwedtag =
    pathname !== "/mybt/intro" &&
    followedTags.some(
      (followedTag) => followedTag.name.toLowerCase() === tag.name.toLowerCase()
    );

  const handleClick = () => {
    if (userType === OKTAUserTypeEnum.ANONYMOUS) {
      mySPHOpenLogin();
    }

    if (
      userType === OKTAUserTypeEnum.REGISTERED ||
      userType === OKTAUserTypeEnum.SUBSCRIBER
    ) {
      const apiToCall = follwedtag ? handleMinusTag : handleAddTag;

      apiToCall();
    }
  };

  const handleAddTag = () => {
    setIsLoading(true);
    setErrorMessages(false);
    if (
      followedTags.length ===
      (userType === OKTAUserTypeEnum.SUBSCRIBER
        ? MYBT_SUB_LIMIT
        : MYBT_REG_LIMIT)
    ) {
      setIsLimitModalShown(true);
      setIsLoading(false);
      return;
    }

    const fetchAPI = async () => {
      const response = await sectionRequests.updateMyBtFollowedKeywords(
        userType,
        mysphw ?? "",
        [tag]
      );

      if (
        response.code === HttpStatusCode.Success &&
        response.message === "SUCCESS" &&
        response.errors &&
        response.errors.length > 0
      ) {
        setErrorMessages(true);
        setIsModalShown(true);
      }

      if (
        response.code === HttpStatusCode.Success &&
        response.message == "SUCCESS" &&
        (!response.errors || response.errors.length === 0)
      ) {
        addTag(tag);
        setIsModalShown(true);
        gaEventTracker("mybt", "add", tag.name);
      }
      if (response.code === HttpStatusCode.KeywordLimitReached) {
        setIsLimitModalShown(true);
      }
      if (pathname === RouteFactory.myBTIntro)
        navigate(RouteFactory.myBTOnboard);

      setIsLoading(false);
    };

    fetchAPI();
  };

  const handleMinusTag = () => {
    setIsLoading(true);

    const fetchAPI = async () => {
      const response = await sectionRequests.deleteMyBtFollowedKeywords(
        userType,
        mysphw ?? "",
        [tag]
      );

      if (
        response.code === HttpStatusCode.Success &&
        response.message == "SUCCESS"
      ) {
        minusTag(tag);
        gaEventTracker("mybt", "remove", tag.name);
      }
      setIsLoading(false);
    };

    fetchAPI();
  };

  return (
    <div
      data-testid={testId}
      className={cx(
        rootClassName,
        pathname === RouteFactory.myBTOnboard && follwedtag
          ? "relative border-black bg-black text-white"
          : "relative",
        pathname === RouteFactory.myBTOnboard && !follwedtag
          ? "border-gray-850 bg-white"
          : ""
      )}
      onMouseEnter={() => {
        setIsTooltipOpen(true);
      }}
      onMouseLeave={() => {
        setIsTooltipOpen(false);
      }}
    >
      <div className="group relative">
        <div className="flex items-center">
          {isLinkenabled && tag.uri ? (
            <Link
              to={getKeywordLink(tag)}
              className={cn(
                "line-clamp-1 place-self-center break-all px-3 text-center font-poppins text-base font-semibold capitalize",
                {
                  "mr-3 border-r border-gray-450": !disableBorder,
                },
                tagClassName
              )}
              reloadDocument
            >
              {tagDisplayName}
            </Link>
          ) : (
            <a
              href="#"
              onClick={handleClick}
              className={cn(
                "line-clamp-1 place-self-center break-all px-3 text-center font-poppins text-base font-semibold capitalize",
                {
                  "mr-3 border-r border-gray-450": !disableBorder,
                },
                tagClassName
              )}
            >
              {tagDisplayName}
            </a>
          )}
          <Button
            onPress={handleClick}
            className={cn(
              "ml-auto leading-none",
              {
                "ml-0": withGiftIcon,
              },
              {
                "flex h-[42px] w-[42px] items-center justify-center rounded-full !border !border-solid !border-gray-175 px-3 py-2 !font-poppins text-xs font-semibold tracking-[0.0175rem] text-gray-850 outline-none transition-all duration-150 focus-within:outline-none hover:!border-gray-850 focus:outline-none md:h-auto md:w-auto md:rounded-[50px] md:px-6":
                  followtextButton,
              },
              { "border-gray-850": follwedtag }
            )}
          >
            {followtextButton ? (
              <>
                {!isLoading && !follwedtag ? (
                  <div className="md:min-w-16">
                    <p className="m-0 hidden md:block">Follow</p>
                    <div className="block md:hidden">
                      <FontAwesomeIcon
                        icon={faPlus}
                        spin={isLoading}
                        size="sm"
                        color="text-gray-850"
                        className={iconClassName}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="md:min-w-16">
                    {follwedtag && !isLoading ? (
                      <>
                        <p className="hiddenmd:block m-0">Following</p>
                        <div className="block md:hidden">
                          <FontAwesomeIcon
                            icon={faCheck}
                            spin={isLoading}
                            size="sm"
                            color="text-gray-850"
                            className={iconClassName}
                          />
                        </div>
                      </>
                    ) : null}

                    {!follwedtag || isLoading ? (
                      <FontAwesomeIcon
                        icon={
                          isLoading ? faSpinner : follwedtag ? faCheck : faPlus
                        }
                        spin={isLoading}
                        size="sm"
                        color="text-gray-850"
                        className={iconClassName}
                      />
                    ) : null}
                  </div>
                )}
              </>
            ) : (
              <FontAwesomeIcon
                icon={isLoading ? faSpinner : follwedtag ? faCheck : faPlus}
                spin={isLoading}
                size="sm"
                color="text-gray-850"
                className={iconClassName}
              />
            )}
          </Button>
        </div>

        {isTooltipOpen && !disableTooltip ? (
          <KeywordFilterTooltip
            toolTipClassName={toolTipClassName}
            toolTipArrowClassName={toolTipArrowClassName}
          />
        ) : null}
      </div>
      {isModalShown && (follwedtag || errorMessages)
        ? createPortal(
            <FollowKeywordModal hasError={errorMessages} />,
            document.body
          )
        : null}
    </div>
  );
}
