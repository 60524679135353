import { ReactElement } from "react";
import type { ArticleDataObject } from "@app/types/Cue";
import {
  AdvertisementTypeEnum,
  HeroVariant,
  TagsVariant,
} from "@app/types/enums";
import Advertisement from "@components/Advertisement/Advertisement";
import VerticalHero from "@components/ArticleCard/VerticalHero";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import { VerticalName } from "@util/constant";
import {
  getKickerFromArticleDataObject,
  GoogleAdsSlotFactory,
} from "@util/helpers";
import cx from "classnames";

export type TopStoriesRowProps = {
  testId?: string;
  parentCategory?: string;
  articles: ArticleDataObject[];
  verticalName: VerticalName;
};

const verticalToUniqueNameMapping: {
  verticalName: VerticalName;
  uniqueName: string;
}[] = [
  { verticalName: "asean", uniqueName: "international_asean" },
  { verticalName: "ge", uniqueName: "international_global" },
  { verticalName: "garage", uniqueName: "startups-tech_startups" },
  { verticalName: "sme", uniqueName: "singapore_smes" },
];

export default function TopStoriesRow({
  testId,
  parentCategory,
  articles,
  verticalName,
}: TopStoriesRowProps): ReactElement {
  const topStoryOne = articles.slice(0, 1);
  const topStoriesThree = articles?.slice(1, 4);

  const sectionUniqueName =
    verticalToUniqueNameMapping.find(
      (item) => item.verticalName === verticalName
    )?.uniqueName || "";

  return (
    <>
      {articles.length > 0 ? (
        <>
          <Row rootClassName="mb-6" data-testid={testId}>
            <Column rootClassName={cx("w-full px-3", "lg:w-8/12")}>
              {topStoryOne.map((article) => {
                return (
                  <VerticalHero
                    key={article.id}
                    articleData={article}
                    verticalName={verticalName}
                    tagsVariant={TagsVariant.Inside}
                    heroVariant={HeroVariant.Main}
                    showCreateTime={false}
                    sectionUniqueName={sectionUniqueName}
                    kicker={
                      article?.kicker?.fields.at(0)?.value ||
                      getKickerFromArticleDataObject(article)
                    }
                  />
                );
              })}
            </Column>

            <Column rootClassName={cx("w-full px-3", "lg:w-4/12")}>
              <Advertisement
                rootClassName="py-6 lg:sticky lg:top-[120px] lg:py-0"
                adUnitProps={{
                  type: AdvertisementTypeEnum.IMU2,
                  slot: GoogleAdsSlotFactory.imu2(parentCategory ?? ""),
                }}
              />
            </Column>
          </Row>

          <Row rootClassName="mb-24">
            <Column rootClassName="w-full flex flex-col lg:flex-row gap-6">
              {topStoriesThree?.map(
                (article: ArticleDataObject, index: number) => {
                  return (
                    <div
                      key={article?.id ?? index}
                      className={cx("w-full", "lg:w-4/12")}
                    >
                      <VerticalHero
                        articleData={article}
                        heroVariant={HeroVariant.Secondary}
                        verticalName={verticalName}
                        tagsVariant={TagsVariant.Outside}
                        showCreateTime={false}
                        sectionUniqueName={sectionUniqueName}
                        kicker={
                          article?.kicker?.fields.at(0)?.value ||
                          getKickerFromArticleDataObject(article)
                        }
                      />
                    </div>
                  );
                }
              )}
            </Column>
          </Row>
        </>
      ) : null}
    </>
  );
}
