import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

export const useHookUpdateUrlArticle = (
  urlPath: string,
  isNavigate: boolean
) => {
  const navigate = useNavigate();

  const { ref } = useInView({
    threshold: 0.1,
    onChange: (inView) => {
      if (!inView) return;
      if (!isNavigate) return;

      navigate(urlPath, { preventScrollReset: true });

      if (typeof window === "undefined") return;
      window.dataLayer = window.dataLayer || [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window.dataLayer as any[]).push({
        ...window._data,
        ...{
          event: "virtual_pv",
          virtual_url: urlPath.replace("/", ""),
        },
      });
    },
  });

  return { ref };
};
