import {
  CaaSImageFilters,
  OptimisedImageProps,
} from "@app/types/OptimisedImage";

import { getFormattedCaaSImageUrl } from "./helper";

const defaultFilters: CaaSImageFilters[] = [
  { breakpoint: "(min-width: 992px)", w: 900, h: 600, dpr: 1, f: "webp" },
  { w: 300, h: 200, dpr: 1, f: "webp" },
];

export default function OptimisedImage({
  src,
  filters = defaultFilters,
  ...rest
}: OptimisedImageProps): React.ReactElement {
  const fallbackFilter: CaaSImageFilters = { q: 20, w: 3, h: 2, f: "webp" };

  return (
    <picture>
      {filters?.map(({ breakpoint, ...imageFilters }, index) => {
        const imageDpr = imageFilters.dpr ? `${imageFilters.dpr}x` : "";

        return (
          <source
            key={index}
            media={breakpoint}
            srcSet={`${getFormattedCaaSImageUrl(src, {
              ...imageFilters,
            })} ${imageDpr}`}
          />
        );
      })}
      <img src={getFormattedCaaSImageUrl(src, fallbackFilter)} {...rest} />
    </picture>
  );
}
