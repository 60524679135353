import { Fragment, useState } from "react";
import { useInView } from "react-intersection-observer";
import { ArticleDataObject, seeAlso, Tag } from "@app/types/Cue";
import { AdvertisementTypeEnum } from "@app/types/enums";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import {
  getArticleAuthorNames,
  getArticleChapter1,
  getArticleKeywords,
  getArticleLevel2,
  getArticleStoryThread,
} from "@components/GAData/helper";
import Header from "@components/Header/Header";
import { useArticleHeaderState } from "@components/Header/helpers";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useArticleGiftReceived from "@hooks/useArticleGiftReceived";
import { UseFetchNextArticle } from "@hooks/useFetchNextArticle";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { getFormattedTextForAds } from "@pages/Section/helpers";
import useMyBtStore from "@store/useMyBtStore";
import {
  ARTICLE_INIFINITE_SCROLL_LIMIT,
  dayjsSingaporeTimezone,
} from "@util/constant";
import {
  GoogleAdsSlotFactory,
  isPathAVerticalPath,
  toUnderscoreFromSpace,
} from "@util/helpers";
import dayjs from "dayjs";
import { includes } from "lodash-es";

import ArticleSubshareModal from "./components/ArticleSubshare/ArticleSubshareModal";
import ArticleSubsharePrompt from "./components/ArticleSubshare/ArticleSubsharePrompt";
import ArticleSubshareReceived from "./components/ArticleSubshare/ArticleSubshareReceived";
import { LAMBDA_RESPONSE_TYPES } from "./types/Alacrity";
import { ArticleFactory, VerticalHeaderFactory } from "./utils/helpers";

type ArticleContentProps = {
  context: ArticleDataObject;
  sectionNews: ArticleDataObject[];
  seeAlso: seeAlso;
  gsChannels: string;
  isPreview?: boolean;
};

type ScrollObjArray = {
  id: string;
  scrollW: number;
};

export default function ArticleContent({
  context,
  sectionNews,
  seeAlso,
  gsChannels,
  isPreview,
}: ArticleContentProps): React.ReactElement {
  const [moreStories, setMoreStories] = useState<ArticleDataObject[]>([
    context,
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [showGiftSubscribePrompt, setShowGiftSubscribePrompt] = useState(false);
  const [articleInView, setArticleInView] = useState<ArticleDataObject>();
  const [scrollObj, setScrollObj] = useState<ScrollObjArray[]>([]);
  const {
    isPrestitialEnabled,
    topOverlayImpressions,
    topOverlayValidity,
    isOutbrainEnabled,
  } = useGlobalAdSetting({
    targeting: [{ key: "page", value: "article" }],
  });
  const { isModalOpen, setIsModalOpen, alacrityRes, isGiftReceived } =
    useArticleGiftReceived();
  const myBTTags = useMyBtStore((store) => store.tags);

  // Article data de-construct
  const { sections } = context;

  const mainSection = sections?.at(0);
  const uniqueName = mainSection?.uniqueName;
  const uniqueNameAds = uniqueName?.replace("_", "/") || "";
  const verticalPath = "/" + uniqueNameAds;
  const isThisArticlegifted =
    alacrityRes?.data.responseType ===
      LAMBDA_RESPONSE_TYPES.SUCCESS_VALID_GIFT_TOKEN &&
    !alacrityRes.data.viewGiftStatus;

  const VerticalHeaderLayout = VerticalHeaderFactory({ verticalPath });

  const fetchTheNextArticle = async () => {
    setIsLoading(true);
    const lastArticle = moreStories?.at(-1);
    const nextStoryWShortUrl = await UseFetchNextArticle(lastArticle);
    setMoreStories([...moreStories, nextStoryWShortUrl]);
    setIsLoading(false);
  };

  const { ref } = useInView({
    triggerOnce: true,
    onChange: async (inView) => {
      if (typeof window === "undefined") return;

      const userAgent = window.navigator.userAgent;

      if (
        inView &&
        moreStories.length < ARTICLE_INIFINITE_SCROLL_LIMIT &&
        !includes(userAgent.toLowerCase(), "googlebot")
      )
        fetchTheNextArticle();
    },
  });

  const { ref: showGiftSubscribePromptRef } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView && isThisArticlegifted) setShowGiftSubscribePrompt(true);
    },
  });

  const handleSetArticle = (e: ArticleDataObject) => {
    setArticleInView(e);
  };

  const handleSetScrollW = (id: string, e: number) => {
    const percent = e >= 100 ? 100 : e;
    const tmpA = scrollObj || [];
    const tmpAIndex = tmpA?.findIndex((x) => x.id === id);

    if (tmpAIndex >= 0) {
      tmpA[tmpAIndex].scrollW = percent;
    } else {
      tmpA.push({
        id: id,
        scrollW: percent,
      });
    }

    setScrollObj(tmpA);
  };

  const parentPath = articleInView
    ? articleInView.urlPath.replace("/", "")
    : "";

  const { articleTopRef, isShowArticleHeader } = useArticleHeaderState();

  return (
    <div className="w-full" data-testid="article-content-component">
      {!isPathAVerticalPath(verticalPath) ? (
        <>
          <Advertisement
            rootClassName="min-h-[90px] bg-gray-125 py-1 md:py-6 border-y border-gray-175"
            adUnitProps={{
              type: AdvertisementTypeEnum.LB1,
              slot: GoogleAdsSlotFactory.lb1(uniqueNameAds),
              slotTargettings: [
                { key: "btarticleid", value: context?.id },
                {
                  key: "bttags",
                  value:
                    context?.tags
                      ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
                      .join(", ") || "",
                },
                { key: "gs_channels", value: gsChannels },
              ],
              adsClassName: "min-h-[90px]",
            }}
          />
        </>
      ) : null}

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(uniqueNameAds)}
        slotTargettings={[
          { key: "btarticleid", value: context?.id },
          {
            key: "bttags",
            value:
              context?.tags
                ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
                .join(", ") || "",
          },
          { key: "gs_channels", value: gsChannels },
        ]}
        {...{ isPrestitialEnabled, topOverlayImpressions, topOverlayValidity }}
      />

      <Header
        article={articleInView}
        displayArticle={isShowArticleHeader}
        scrollObj={scrollObj}
        parentCategory={parentPath.split("/")[0]}
      />

      <>
        {VerticalHeaderLayout ? (
          <Container rootClassName="py-2">
            <VerticalHeaderLayout
              slotTargettings={[
                { key: "btarticleid", value: context?.id },
                {
                  key: "bttags",
                  value:
                    context?.tags
                      ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
                      .join(", ") || "",
                },
                { key: "gs_channels", value: gsChannels },
              ]}
            />
          </Container>
        ) : null}

        <div ref={articleTopRef} />

        <div id="article-content-wrapper" className="w-full">
          {moreStories.map((article, index) => {
            // Check if article is undefined
            if (!article) {
              return null;
            }

            const displayType = article.displaySetting?.displayType;
            const mainSection = article.sections?.at(0);
            const ArticleLayout = ArticleFactory({ displayType });
            const isPremium = article.paywall?.contentAccess === "1";

            if (!displayType || !ArticleLayout) {
              return null;
            }

            const publishedDate = dayjs(article.updated)
              .tz(dayjsSingaporeTimezone)
              .format("YYYY-MM-DD HH:mm:ss");

            console.log("----------- gadata [start] -----------"); // eslint-disable-line no-console
            console.log("gadata", article.id); // eslint-disable-line no-console
            console.log("gadata", myBTTags); // eslint-disable-line no-console
            console.log("----------- gadata [end] -----------"); // eslint-disable-line no-console

            return (
              <Fragment key={article.id}>
                <>
                  {mainSection ? (
                    <GAData
                      articleid={article.id}
                      author={getArticleAuthorNames(
                        article.authors,
                        article.relatedContributorProfile
                      )}
                      chapter1={getArticleChapter1(mainSection)}
                      contentcat={isPremium ? 2 : 1}
                      contenttype="1"
                      keyword={getArticleKeywords(article.tags || [])}
                      level2={getArticleLevel2(mainSection)}
                      pubdate={publishedDate}
                      title={toUnderscoreFromSpace(article.title)}
                      product_flag={myBTTags.length > 0 ? "mybt" : "(not set)"}
                      user_keyword={
                        myBTTags.length > 0
                          ? myBTTags.map((tag) => tag.name).join("|")
                          : "(not set)"
                      }
                      story_threads={getArticleStoryThread(
                        article.tags || []
                      )?.name.toLowerCase()}
                    />
                  ) : null}
                </>
                <ArticleLayout
                  article={article}
                  isGiftReceived={isGiftReceived}
                  sectionNews={sectionNews}
                  variant="default"
                  index={index}
                  seeAlso={seeAlso}
                  isNavigate={moreStories.length > 1}
                  setArticleObj={(e) => {
                    handleSetArticle(e);
                  }}
                  setArticleScrollWidth={(id, e) => {
                    handleSetScrollW(id, e);
                  }}
                  isOutbrainEnabled={isOutbrainEnabled}
                  isPreview={isPreview}
                />
                <div ref={ref}></div>
              </Fragment>
            );
          })}
        </div>

        <ArticleSubsharePrompt
          isModalOpen={showGiftSubscribePrompt}
          setIsModalOpen={setShowGiftSubscribePrompt}
        />

        <div
          aria-roledescription="article-subshare-prompt-observer"
          ref={showGiftSubscribePromptRef}
        ></div>

        {isLoading ? (
          <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} size="5x" spin className="mb-6" />
          </div>
        ) : null}

        <Advertisement
          adUnitProps={{
            type: AdvertisementTypeEnum.CATFISH,
            slot: GoogleAdsSlotFactory.catfish(uniqueNameAds),
            slotTargettings: [
              { key: "btarticleid", value: context?.id },
              {
                key: "bttags",
                value:
                  context?.tags
                    ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
                    .join(", ") || "",
              },
              { key: "gs_channels", value: gsChannels },
            ],
          }}
        />

        <Advertisement
          adUnitProps={{
            type: AdvertisementTypeEnum.ABM,
            slot: GoogleAdsSlotFactory.abm(),
            slotTargettings: [
              { key: "btarticleid", value: context?.id },
              {
                key: "bttags",
                value:
                  context?.tags
                    ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
                    .join(", ") || "",
              },
              { key: "gs_channels", value: gsChannels },
            ],
          }}
        />

        <>
          {alacrityRes &&
          typeof alacrityRes.data.viewGiftStatus !== "undefined" ? (
            <ArticleSubshareModal
              isModalOpen={isModalOpen && !alacrityRes.data.viewGiftStatus}
              setIsModalOpen={setIsModalOpen}
            >
              <ArticleSubshareReceived
                alacrityRes={alacrityRes}
                setIsModalOpen={setIsModalOpen}
              />
            </ArticleSubshareModal>
          ) : null}
        </>
      </>
    </div>
  );
}
