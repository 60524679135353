import { CaaSImageFilters } from "@app/types/OptimisedImage";
import { stringify } from "querystringify";

export const getFormattedCaaSImageUrl = (
  url?: string,
  filters?: CaaSImageFilters
) => {
  if (!url) return "";
  if (!filters) return url;
  // https://d32cmb05ummg49.cloudfront.net/image/businesstimes/d01aab779738beb9b5e5bf88d33a972d4276019ad81c7f9632b9c879a9db3563.jpg
  return `${url}?${stringify(filters)}`;
};
