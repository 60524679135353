import { Queryly } from "@app/types/Queryly";
import { QUERYLY_SCRIPT } from "@util/constant";

import { loadScript } from "../hooks/useScript";

let promise: Promise<Queryly>;

export default function loadQueryly() {
  if (!promise) {
    promise = loadScript({ src: QUERYLY_SCRIPT, id: "queryly" }).then(() => {
      window.queryly.init(
        import.meta.env.VITE_QUERYLY_TOKEN,
        document.querySelectorAll(
          "header,footer,.container,.block-component,#content-container,#footer-container,#background_container"
        )
      );
      return window.queryly;
    });
  }

  return promise;
}
