import { RouteFactory } from "@app/routePaths";
import { BT_NEWSLETTERS } from "@components/Newsletter/types/Newsletter";

export const NEWSLETTER_PAGE_LINK = RouteFactory.newsletterSignup;
export const PREMIUM_NEWSLETTERS = BT_NEWSLETTERS.slice(8);
export const DAILY_NEWSLETTERS = BT_NEWSLETTERS.slice(0, 2);
export const WEEKLY_NEWSLETTERS = BT_NEWSLETTERS.slice(2, 8);
export const newsletterLinks = [
  { linkText: "Premium Newsletters" },
  { linkText: "Daily Newsletters" },
  { linkText: "Weekly Newsletters" },
];

export const BIGMONEY_PAGE_LINK = RouteFactory.newsletterBigMoneyArchivePage;
export const BIGMONEY_SUBSCRIBE_CTA =
  "https://subscribe.sph.com.sg/promotions/bt-personal/?utm_campaign=bigmoneynewsletter&utm_medium=sph_publication&utm_source=bt&utm_content=newsletterarchivesubscribelink";

export const BIGMONEY_TITLE = "Big Money";
export const BIGMONEY_DESC =
  "A weekly breakdown of the latest developments affecting financial markets.";
export const BIGMONEY_CONTRIBUTORS = [
  {
    imageSrc:
      "https://static1.businesstimes.com.sg/s3fs-public/styles/profile_photo/public/author_profile/2022/04/29/joan20ng.png",
    quote:
      "“Making money in financial markets requires eyes and ears to collect information, and multiple perspectives from which to assess it. The more perspectives you have, the more useful your intelligence will be.”",
    name: "Joan Ng, Senior Correspondent",
  },
];

export const BIGMONEY_POSTS = [
  {
    title:
      "Issue 101: Singapore keeps cheap green borrowing streak alive; Indonesia’s mixed signals on coal",
    link: "/esg/issue-101-singapore-keeps-cheap-green-borrowing-streak-alive-indonesias-mixed-signals-coal",
    date: "May 24, 2024",
  },
  {
    title:
      "Issue 100: Lost MSCI, still got ESG; a mixed bag for Asia’s blended finance in 2023",
    link: "/esg/issue-100-lost-msci-still-got-esg-mixed-bag-asias-blended-finance-2023",
    date: "May 17, 2024",
  },
];

export const BIGMONEY_BIG_READS = [
  {
    title: "Telco M1 struggles to find a fit in Keppel’s transformation plans",
    articleUrl:
      "https://www.businesstimes.com.sg/opinion-features/columns/telco-m1-struggles-find-fit-keppels-transformation-plans?utm_source=emarsys&amp;utm_medium=email&amp;utm_campaign=bt_big_money&amp;utm_term=Telco+M1+struggles+to+find+a+fit+in+Keppel%E2%80%99s+transformation+plans+&amp;utm_content=09%2F06%2F2024",
    description:
      "WHEN rumours of a possible merger between StarHub and M1 emerged yet again in May, it caused a bit of a stir among market observers.",
  },
  {
    title:
      "Few exits, limited investor appetite to test South-east Asia’s PE managers",
    articleUrl:
      "https://www.businesstimes.com.sg/international/asean/few-exits-limited-investor-appetite-test-south-east-asias-pe-managers?utm_source=emarsys&amp;utm_medium=email&amp;utm_campaign=bt_big_money&amp;utm_term=Few+exits%2C+limited+investor+appetite+to+test+South-east+Asia%E2%80%99s+PE+managers&amp;utm_content=09%2F06%2F2024",
    description:
      "PRIVATE equity (PE) funds operating in South-east Asia will need a strong domestic presence to pull off roll-ups and trade sales in the face of mounting challenges, including difficulties exiting investments and raising funds.",
  },
  {
    title:
      "New sponsors of Ara H-Trust, Cromwell E-Reit unlikely to quickly lift their depressed valuations",
    articleUrl:
      "https://www.businesstimes.com.sg/opinion-features/new-sponsors-ara-h-trust-cromwell-e-reit-unlikely-quickly-lift-their-depressed-valuations?utm_source=emarsys&amp;utm_medium=email&amp;utm_campaign=bt_big_money&amp;utm_term=New+sponsors+of+Ara+H-Trust%2C+Cromwell+E-Reit+unlikely+to+quickly+lift+their+depressed+valuations&amp;utm_content=09%2F06%2F2024",
    description:
      "INVESTORS may have mixed feelings about the recently announced sale of ARA US Hospitality Trust (ARA H-Trust) and Cromwell European Real Estate Investment Trust (Cromwell E-Reit) by their respective sponsor groups.",
  },
  {
    title: "Singapore builder Oxley seeks up to US$120 million private loan",
    articleUrl:
      "https://www.businesstimes.com.sg/property/singapore-builder-oxley-seeks-us120-million-private-loan?utm_source=emarsys&amp;utm_medium=email&amp;utm_campaign=bt_big_money&amp;utm_term=Singapore+builder+Oxley+seeks+up+to+US%24120+million+private+loan&amp;utm_content=09%2F06%2F2024",
    description:
      "OXLEY Holdings, a cash-strapped property developer in Singapore, is seeking a private credit loan of US$100 million to US$120 million partly to help repay a Singapore dollar bond due next month, said sources familiar with the matter.",
  },
  {
    title:
      "‘Modi premium’ in India’s financial markets set to erode after weak victory",
    articleUrl:
      "https://www.businesstimes.com.sg/international/modi-premium-indias-financial-markets-set-erode-after-weak-victory?utm_source=emarsys&amp;utm_medium=email&amp;utm_campaign=bt_big_money&amp;utm_term=%E2%80%98Modi+premium%E2%80%99+in+India%E2%80%99s+financial+markets+set+to+erode+after+weak+victory&amp;utm_content=09%2F06%2F2024",
    description:
      "INDIAN voters’ tepid endorsement of Prime Minister Narendra Modi leaves a weakened mandate for business-friendly reforms and has foreign money managers thinking twice about unleashing another wave of investment in the world’s fastest-growing economy.",
  },
];

export const NEWSLETTER_FOOTER_SOCIAL_LINKS = [
  {
    icon: "https://static1.businesstimes.com.sg/s3fs-public/fb.png",
    url: "https://www.facebook.com/thebusinesstimes/",
  },
  {
    icon: "https://static1.businesstimes.com.sg/s3fs-public/nl-twitter.png",
    url: "https://twitter.com/businesstimes/",
  },
  {
    icon: "https://static1.businesstimes.com.sg/s3fs-public/linkedin.png",
    url: "https://www.linkedin.com/showcase/the-business-times/",
  },
  {
    icon: "https://static1.businesstimes.com.sg/s3fs-public/tgram.png",
    url: "https://t.me/BizTimes",
  },
  {
    icon: "https://static1.businesstimes.com.sg/s3fs-public/insta.png",
    url: "https://www.instagram.com/businesstimessg/",
  },
];

export const BIGMONEY_OTHER_READS = [
  {
    title: "Property Insights",
    description:
      "Get an exclusive analysis of real estate and property news in Singapore and beyond.",
    link: "https://www.businesstimes.com.sg/newsletter/property",
    image:
      "https://static1.businesstimes.com.sg/s3fs-public/image/2023/03/09/newsletter_property.png",
  },
  {
    title: "ESG Insights",
    description:
      "An exclusive weekly report on the latest environmental, social and governance issues.",
    link: "https://www.businesstimes.com.sg/newsletter/esg",
    image:
      "https://static1.businesstimes.com.sg/s3fs-public/image/2023/03/09/newsletter_esg.png",
  },
  {
    title: "Breakfast Brief",
    description:
      "All the latest news you need to know to start your day, right in your inbox.",
    link: "https://www.businesstimes.com.sg/newsletter/sign-up",
    image:
      "https://static1.businesstimes.com.sg/s3fs-public/image/2023/03/09/newsletter_breakfast_brief.png",
  },
];
