/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/**
 * Company: SPHMedia
 * Description: Default Section Layout
 */

import { ReactElement, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { Await } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { WealthContext } from "@app/types/Page";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import CustomError from "@components/Error/CustomError";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import Spinner from "@components/Spinner/Spinner";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { sectionNavigationItems } from "@util/constant";
import { useContext } from "@util/getContext";
import { GoogleAdsSlotFactory } from "@util/helpers";

import TodaysMarketData from "./components/TodaysMarketData";
import WealthRealEstate from "./components/WealthRealEstate";
import WealthThirdRow from "./components/WealthThirdRow";
import WealthTopStories from "./components/WealthTopStories";
import WealthWatches from "./components/WealthWatches";

export default function WealthPage({
  testId,
}: {
  testId?: string;
}): ReactElement {
  const context: WealthContext = useContext();
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: { key: "page", value: "listing" },
    });
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();
  return (
    <Suspense fallback={<Spinner />}>
      <Await resolve={context} errorElement={<CustomError />}>
        {({
          kind,
          error,
          data: { title, wealth, property, invest, advisor, asset, watches },
        }) => {
          return kind !== "section" || error ? (
            <h1>Error</h1>
          ) : (
            <>
              <Helmet>
                <title>{title}</title>
              </Helmet>

              <GAData
                level2={"wealth"}
                title={"Wealth_Index"}
                adblocker={
                  typeof window !== "undefined" ? 0 : (window as any).canRunAds
                }
              />

              <Advertisement
                rootClassName="min-h-[90px] bg-gray-125 py-1 md:py-6 border-y border-gray-175"
                adUnitProps={{
                  type: AdvertisementTypeEnum.LB1,
                  slot: GoogleAdsSlotFactory.lb1(
                    RouteFactory.wealth.replace("/", "")
                  ),
                  adsClassName: "min-h-[90px]",
                }}
              />

              <PrestitialAdWrapper
                slot={GoogleAdsSlotFactory.prestitial(
                  RouteFactory.wealth.replace("/", "")
                )}
                {...{
                  isPrestitialEnabled,
                  topOverlayImpressions,
                  topOverlayValidity,
                }}
              />

              <Header
                displayBreadcrumb={
                  sectionNavigationItems["wealth"] && isShowBreadCrumb
                }
                parentCategory={"wealth"}
                categoryDataMapping={sectionNavigationItems}
              />

              {sectionNavigationItems["wealth"] ? (
                <Container>
                  <div>
                    <h1 className="pb-3 pt-6 font-playfair text-8xl font-semibold uppercase text-gray-850">
                      {"Wealth"}
                    </h1>

                    <div ref={breadcrumbsRef}>
                      <Breadcrumb
                        rootClassName="m-auto"
                        parentCategory={"wealth"}
                        categoryDataMapping={sectionNavigationItems}
                      />
                    </div>
                  </div>
                </Container>
              ) : null}

              <Container rootClassName="pt-6" data-testid={testId}>
                <Row rootClassName="mb-6">
                  <Column rootClassName="w-full px-3 lg:w-8/12">
                    <WealthTopStories data={wealth} />
                  </Column>

                  <Column rootClassName="w-full px-3 lg:w-4/12">
                    <Advertisement
                      rootClassName="bg-stripes mb-5 py-5"
                      adUnitProps={{
                        type: AdvertisementTypeEnum.IMU1,
                        slot: GoogleAdsSlotFactory.imu1(
                          RouteFactory.wealth.replace("/", "")
                        ),
                      }}
                    />

                    <TodaysMarketData />
                  </Column>
                </Row>

                <>
                  {property ? (
                    <Row rootClassName="mb-6">
                      <Column rootClassName="w-full">
                        <WealthRealEstate data={property} />
                      </Column>
                    </Row>
                  ) : null}
                </>

                <>
                  {invest && advisor && asset ? (
                    <Row rootClassName="mb-6">
                      <Column rootClassName="w-full">
                        <WealthThirdRow
                          invest={invest}
                          advisor={advisor}
                          asset={asset}
                        />
                      </Column>
                    </Row>
                  ) : null}
                </>

                <>
                  {watches ? (
                    <Row rootClassName="mb-6">
                      <Column rootClassName="w-full">
                        <WealthWatches articles={watches} />
                      </Column>
                    </Row>
                  ) : null}
                </>
              </Container>

              <Advertisement
                adUnitProps={{
                  type: AdvertisementTypeEnum.CATFISH,
                  slot: GoogleAdsSlotFactory.catfish(
                    RouteFactory.wealth.replace("/", "")
                  ),
                }}
              />

              <Advertisement
                adUnitProps={{
                  type: AdvertisementTypeEnum.ABM,
                  slot: GoogleAdsSlotFactory.abm(),
                }}
              />
            </>
          );
        }}
      </Await>
    </Suspense>
  );
}
