import { ReactElement } from "react";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import { BlockTitle } from "@src/app/blocks/Blocks";
import BasicCard from "@src/app/components/ArticleCard/BasicCard";
import { CardVariations } from "@src/app/components/ArticleCard/types";
import { ArticleDataObject } from "@src/app/types/Cue";
import { getArticleBlurb } from "@src/app/util/helpers";

export type WealthThirdRowProps = {
  invest: ArticleDataObject[];
  advisor: ArticleDataObject[];
  asset: ArticleDataObject[];
};

export default function WealthThirdRow({
  invest,
  advisor,
  asset,
}: WealthThirdRowProps): ReactElement {
  const columns = [
    {
      articles: invest,
      blockTitle: "INVEST",
    },
    {
      articles: advisor,
      blockTitle: "TRUSTED ADVISOR",
    },
    {
      articles: asset,
      blockTitle: "MANAGER",
    },
  ];

  const variant: CardVariations = {
    content: {
      width: "w-full",
      extraClass: "bg-gray-150 p-3",
    },
    kicker: {
      color: "text-gray-515",
    },
  };

  return (
    <>
      <Row data-testid="wealth-third-row-component">
        <Column rootClassName="w-full">
          <div className="border-t border-black pt-3"></div>
        </Column>

        <>
          {columns.map(({ articles, blockTitle }) => {
            return (
              <Column
                rootClassName="pb-6 w-full md:w-6/12 lg:w-4/12 flex flex-col"
                key={blockTitle}
              >
                <BlockTitle text={blockTitle} rootClassName="mb-3" />

                <>
                  {articles.map((article) => {
                    const {
                      id,
                      media,
                      title,
                      kicker,
                      paywall,
                      created,
                      updated,
                      displaySetting,
                      slug,
                    } = article;

                    return (
                      <BasicCard
                        key={id}
                        id={id}
                        slug={slug}
                        media={media}
                        kicker={kicker?.fields.at(0)?.value}
                        title={title}
                        blurb={getArticleBlurb(article)}
                        paywall={paywall?.contentAccess === "1"}
                        variations={variant}
                        created={created}
                        updated={updated}
                        hasUpdatedTimestampDisplay={
                          displaySetting?.hasUpdatedTimeDisplayed
                        }
                      />
                    );
                  })}
                </>
              </Column>
            );
          })}
        </>
      </Row>
    </>
  );
}
