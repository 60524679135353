import { useEffect, useState } from "react";
import type { DrupalArticleAttachmentObject } from "@app/types/Drupal";
import * as Sentry from "@sentry/react";
import axios from "axios";

const fetchArticleAttachments = async (
  cueId: string
): Promise<DrupalArticleAttachmentObject[]> => {
  try {
    const response = await axios.get<DrupalArticleAttachmentObject[]>(
      `/_plat/api/v1/article-attachments?cue_id=${cueId}`
    );
    return response.data || [];
  } catch (e) {
    return [];
  }
};

export default function useArticleAttachments(
  cueId: string
): DrupalArticleAttachmentObject[] {
  const [data, setData] = useState<DrupalArticleAttachmentObject[]>([]);

  useEffect(() => {
    const callFetch = async () => {
      setData(await fetchArticleAttachments(cueId));
    };

    try {
      callFetch();
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [cueId]);

  return data;
}
