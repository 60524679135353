import type { ArticleDataObject } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { useWindowSize } from "@hooks/useWindowSize";
import cx from "classnames";

export default function PodcastsListingStories({
  id,
  displaySetting,
  title,
  sections,
  kicker,
  media,
  blurb,
  urlPath,
  paywall,
  edited,
}: ArticleDataObject): React.ReactElement {
  const { isScreenLG } = useWindowSize();

  const variant: CardVariations = {
    kicker: {
      color: "text-gray-515",
    },
    image: {
      width: "w-1/4",
      position: "left",
      filters: [
        { breakpoint: "(min-width: 992px)", w: 900, h: 600, dpr: 1, f: "webp" },
        { breakpoint: "(min-width: 768px)", w: 600, h: 400, dpr: 1, f: "webp" },
        { w: 300, h: 200, dpr: 1, f: "webp" },
      ],
    },
    content: {
      width: cx("w-3/4"),
      extraClass: cx("pl-4"),
      blurb: {
        extraClass: "text-gray-850",
      },
    },
    title: {
      size: "text-base",
      color: "text-gray-850",
    },
  };

  return (
    <div className="story border-gray-175 pb-6">
      <BasicCard
        id={id}
        title={displaySetting?.displayHeadline || title}
        kicker={kicker?.fields?.[0]?.value}
        media={media?.at(0)}
        blurb={isScreenLG ? blurb : undefined}
        slug={urlPath}
        paywall={paywall?.contentAccess === "1"}
        variations={variant}
        edited={edited}
        hasUpdatedTimestampDisplay={displaySetting?.hasUpdatedTimeDisplayed}
        defaultImage={{
          directoryName: sections?.at(0)?.uniqueName || "",
        }}
      />
    </div>
  );
}
