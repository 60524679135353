import { Await } from "react-router-dom";
import { HomeContext } from "@app/types/Page";
import CustomError from "@components/Error/CustomError";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";

import HomeContent from "./HomeContent";

export default function HomePage(): React.ReactElement {
  const { context }: CustomContext<HomeContext> = useRouteContext();

  return (
    <Await resolve={context} errorElement={<CustomError />}>
      {({ kind, error, data }) => {
        return kind !== "home" || error ? (
          <h1>Missing context</h1>
        ) : (
          <HomeContent {...data} />
        );
      }}
    </Await>
  );
}
