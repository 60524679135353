import { Field } from "@app/types/Cue";
import { Type } from "@app/types/enums";
import { useTextRangesWithAnnotations } from "@hooks/useTextRangesWithAnnotations";
import { cn } from "@src/app/util/helpers";

export type SubHeadingProps = {
  className?: string;
  fields: Field[];
  forceHeadingCss?: boolean;
};

export const headingClassName = (headingTag: string): string => {
  switch (headingTag) {
    case "h2":
      return "text-[21px] md:text-8xl";
    case "h3":
      return "text-lg md:text-6xl";
    case "h4":
      return "text-base md:text-4xl";
    case "h5":
      return "text-base md:text-xl";
    default:
      return "";
  }
};

export default function SubHeading({
  className,
  fields,
  forceHeadingCss,
}: SubHeadingProps): React.ReactElement {
  const text =
    fields.find((field) => field.name === Type.SubHeadField)?.value || "";
  const heading = fields.find((field) => field.name === Type.Headings)?.value;
  const annotations =
    fields.find((field) => field.name === Type.SubHeadField)?.annotations || [];
  const HeadingTag = heading as "span" | "h2" | "h3" | "h4" | "h5" | "h6";
  const { componentToRender } = useTextRangesWithAnnotations(text, annotations);

  const headingClass =
    (forceHeadingCss && headingClassName(heading || "")) || "";

  return (
    <HeadingTag
      className={cn(className, headingClass)}
      data-story-element={Type.SubHead}
      data-testid="sub-heading-component"
    >
      {componentToRender}
    </HeadingTag>
  );
}
