import * as React from "react";
import { TestIdClassNameProps } from "@app/types/Common";
import type { ArticleDataObject } from "@app/types/Cue";
import type { NavigationItem } from "@app/types/Navigation";
import { BlockTitle } from "@blocks/Blocks";
import { CardVariations } from "@components/ArticleCard/types";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import BtLuxeCarousel from "@components/Slider/BtLuxeSlider";
import HomepageLifestyleStories from "@pages/Home/Lifestyle/HomepageLifestyleStories";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";

export type LifestyleProps = TestIdClassNameProps & {
  data: ArticleDataObject[];
  carouselData: ArticleDataObject[];
};

const section: NavigationItem = sectionNavigationItems["lifestyle"];

export default function Lifestyle({
  testId,
  rootClassName,
  data = [],
  carouselData = [],
}: LifestyleProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const variant: CardVariations = {
    image: {
      width: "w-full lg:w-13/20",
      position: "left",
      filters: [
        { breakpoint: "(min-width: 992px)", w: 900, h: 600, dpr: 1, f: "webp" },
        { breakpoint: "(min-width: 768px)", w: 696, h: 464, dpr: 1, f: "webp" },
        { w: 401, h: 267, dpr: 1, f: "webp" },
      ],
    },
    content: {
      width: "w-full lg:w-7/20 text-white",
      extraClass:
        "lg:pl-4 justify-center items-center lg:items-start lg:justify-start mt-3 lg:mt-0",
    },
    title: {
      size: "text-xl lg:text-6xl",
      color: "text-white",
      extraClass: "text-center lg:text-left",
    },
    kicker: {
      color: "text-gray-515",
      extraClass: "uppercase",
    },
  };

  return (
    <section className={rootClassName} data-testid={testId}>
      <Row>
        <Column rootClassName="w-full lg:w-4/12 mb-6 mt-4">
          <BlockTitle
            link={section?.link}
            text={section.label?.toUpperCase()}
            rootLinkClassName="!text-white"
            rootClassName="pb-2"
          />

          <div className="stories">
            {data.map((article) => {
              return (
                <HomepageLifestyleStories
                  key={article.id}
                  article={article}
                  disablePaywall={
                    OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
                  }
                />
              );
            })}
          </div>
        </Column>

        <Column rootClassName="w-full lg:w-8/12 mb-6 mt-4">
          <BtLuxeCarousel
            data={carouselData}
            testid="lifestyle-slider-list"
            variations={variant}
            dotsCustomClass="home-lifestyle-carousel-dots"
            btLuxeClass=""
            disablePaywall={
              OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
            }
          />
        </Column>
      </Row>
    </section>
  );
}
