import type { SearchResultItemData } from "@app/types/Cue";
import type { SearchContext } from "@app/types/Page";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { DataFetcher } from "@src/app/data";
import {
  OpensearchSearchLambdaAxiosResponseType,
  OpensearchSearchLambdaRequestConfig,
} from "@src/app/types/Opensearch";
import { renderPageTitle } from "@src/app/util/helpers";
import { http } from "@util/httpClient";
import { AxiosResponse } from "axios";

export const searchPageRequests = {
  fetchOpenSearch: async (
    params: Partial<OpensearchSearchLambdaRequestConfig>
  ) =>
    http.post<
      OpensearchSearchLambdaRequestConfig,
      AxiosResponse<
        OpensearchSearchLambdaAxiosResponseType<SearchResultItemData>
      >
    >("/search/", {
      searchResults: {
        isSearch: true,
        sortLatest: true,
        breakingNews: false,
        size: 20,
        ...params,
      },
    }),
};

export const fetchSearchPageData: DataFetcher<
  SearchContext,
  Record<string, unknown>
> = async (params: Record<string, unknown>) => {
  const [openSearchResponse] = await Promise.all([
    searchPageRequests.fetchOpenSearch({
      q: params?.[1] as string,
    }),
  ]);

  return {
    type: ResponseType.SUCCESS,
    statusCode: 200,
    kind: "search",
    data: {
      title: renderPageTitle(params[0] as string, {
        query: params[1] as string,
      }),
      results: openSearchResponse?.data?.body?.searchResults?.body,
      aggregation: openSearchResponse?.data?.body?.searchResults?.aggregation,
    },
  };
};
