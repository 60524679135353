import { Media } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { BrightcoveVideo } from "@components/Brightcove/utils/types";
import { textToSlug } from "@util/helpers";
import cx from "classnames";

export type VideosStoriesProps = BrightcoveVideo & {
  index: number;
};

export default function VideosStories({
  id,
  name,
  images,
  index,
}: VideosStoriesProps): React.ReactElement {
  const isLeadArticle = index === 0;
  const media: Media = {
    content: {
      type: "picture",
      fields: {
        landscape: {
          url: images.poster.src,
        },
      },
    },
  };

  const variant: CardVariations = {
    image: {
      position: "",
      width: "w-120px lg:w-full",
      isVideo: true,
      extraClass: "h-fit",
      aspectRatio: "aspect-16x9",
    },
    content: {
      width: "w-full-120px lg:w-full",
      extraClass: cx({
        "pl-4 lg:pl-0 lg:pt-4": isLeadArticle,
      }),
    },
    title: {
      color: "!text-gray-850",
      size: isLeadArticle ? "text-lg" : "text-base",
    },
  };

  return (
    <div
      className={cx("video border-b border-gray-175 py-3", {
        "border-none": index === 2,
      })}
    >
      <BasicCard
        id={id}
        slug={`/videos/${textToSlug(name)}/${id}`}
        title={name}
        variations={variant}
        media={isLeadArticle ? media : undefined}
      />
    </div>
  );
}
