import * as React from "react";
import Slider, { Settings } from "react-slick";
import { TestIdClassNameProps } from "@app/types/Common";
import SkyboxCard from "@components/ArticleCard/SkyboxCard";
import useSkyboxArticles from "@hooks/useSkyboxArticles";
import cx from "classnames";

import WhatsappQRSkybox from "./WhatsappQRSkybox";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "@components/Skybox/Skybox.module.scss";

export default function HomeSkybox({
  rootClassName = "",
  testId = "homepage-skybox-list",
}: TestIdClassNameProps): React.ReactElement {
  const skyboxArticles = useSkyboxArticles();

  const SliderSkyboxComponent =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    typeof window === "undefined" ? Slider.default : Slider;

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    swipeToSlide: true,
    dotsClass: "skybox-dots !flex justify-center mt-4",
    customPaging: () => (
      <button className="duration-all m-1.5 h-4 w-4 scale-100 rounded-full bg-gray-500 p-0 transition-all before:hidden"></button>
    ),
    arrows: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div
      data-testid={testId}
      className={cx(rootClassName, styles.componentSkybox)}
    >
      {skyboxArticles.length ? (
        <SliderSkyboxComponent {...settings}>
          <WhatsappQRSkybox />

          {skyboxArticles.map((skyboxArticle) => {
            return <SkyboxCard key={skyboxArticle.title} {...skyboxArticle} />;
          })}
        </SliderSkyboxComponent>
      ) : null}
    </div>
  );
}
