import { useStockData } from "@components/StockPickerWidget/helpers";
import { ArticleStockData } from "@pages/Article/components/ArticleStockSidebar/ArticleStockData";

interface ArticleStockCodeProps {
  code: string;
}

export function ArticleStockItem({
  code,
}: ArticleStockCodeProps): React.ReactElement {
  const { stockData } = useStockData(code);

  return <>{stockData ? <ArticleStockData stockData={stockData} /> : null}</>;
}
