import hamburger from "@assets/hamburger-menu.svg";
import Button from "@src/app/components/AccessibleComponents/Button";
import cx from "classnames";

export type ToggleSideMenuButtonProps = {
  onClick: () => void;
  rootClassName?: string;
};

export default function ToggleSideMenuButton({
  onClick,
  rootClassName,
}: ToggleSideMenuButtonProps): React.ReactElement {
  return (
    <Button
      aria-label="Toggle side menu"
      className={cx(
        "block flex-none cursor-pointer opacity-100 duration-300",
        "border-0 !outline-none focus-within:outline-0 hover:rounded-full hover:bg-gray-250 focus:outline-0",
        rootClassName
      )}
      onPress={onClick}
    >
      <img
        className="h-full w-full"
        src={hamburger}
        width={48}
        height={48}
        alt="hamburger icon"
      />
    </Button>
  );
}
