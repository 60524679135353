import giftIcon from "@assets/icon-gift.svg";
import giftIconMain from "@assets/icon-gift-main.svg";
import Button from "@components/AccessibleComponents/Button";
import { LambdaResponsePayload } from "@pages/Article/types/Alacrity";
import { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { cn } from "@util/helpers";

export type ArticleSubshareProps = {
  isPremium: boolean;
  userType?: OKTAUserTypeEnum;
  handleGenerateGiftURLModal: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  alacrityRes?: LambdaResponsePayload;
  displayNewGiftIcon?: boolean;
};

export default function ArticleSubshare({
  isPremium = false,
  userType = OKTAUserTypeEnum.ANONYMOUS,
  handleGenerateGiftURLModal,
  setIsModalOpen,
  alacrityRes,
  displayNewGiftIcon = false,
}: ArticleSubshareProps): React.ReactElement {
  const handleClick = () => {
    if (alacrityRes) {
      setIsModalOpen(true);
    } else {
      handleGenerateGiftURLModal();
    }
  };

  return (
    <>
      {isPremium && userType === OKTAUserTypeEnum.SUBSCRIBER ? (
        <div data-testid="article-subshare-component">
          <Button
            onPress={handleClick}
            className={cn("h-10 w-10 outline-none hover:opacity-70", {
              "flex h-[42px] w-[42px] rounded-full border border-gray-175 hover:border-gray-850 hover:opacity-100":
                displayNewGiftIcon,
            })}
            aria-label="Gift this subscriber-only story to your friends and family"
          >
            <img
              className="h-full w-full"
              src={displayNewGiftIcon ? giftIconMain : giftIcon}
              width={42}
              height={42}
              alt="Share this article."
            />
          </Button>
        </div>
      ) : null}
    </>
  );
}
