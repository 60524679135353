import { useState } from "react";
import loadingGif from "@assets/loading-small.gif";
import Button from "@src/app/components/AccessibleComponents/Button";
import { getErrorMessage } from "@util/helpers";
import cx from "classnames";

import styles from "./LoadMore.module.scss";

interface LoadMoreProps {
  rootClassName?: string;
  onLoadMore: (
    ...rest: (unknown & string & number & Record<string, unknown>)[]
  ) => Promise<unknown>;
  hasMore?: boolean;
  loadText?: string;
}

/**
 *
 * @param onLoadMore the action will be performed when user click the load more button, from the design point of view
 * the load more component should not "understand" or "care" about the action content
 * @param hasMore this boolean indicator from parent logic to tell the component to hide itself or not
 * @returns
 */
export default function LoadMore({
  rootClassName = "",
  onLoadMore,
  hasMore = true,
  loadText = "More Stories",
}: LoadMoreProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const handleLoadMore = async () => {
    setIsLoading(true);

    if (onLoadMore) {
      try {
        await onLoadMore();
      } catch (_error: unknown) {
        setError(getErrorMessage(_error));
      }
    }

    setIsLoading(false);
  };

  const renderButtonText = () => {
    if (error) {
      return error;
    }

    return loadText;
  };

  return (
    <div
      className={cx(
        styles.componentLoadMore,
        "cursor-pointer border-y-[2px] border-[var(--digital-black)] py-[var(--gutter-y)] text-center",
        {
          hidden: !hasMore,
        },
        rootClassName
      )}
      onClickCapture={handleLoadMore}
    >
      <Button className="uppercase outline-none">{renderButtonText()}</Button>
      <LoadingGif isLoading={isLoading} />
    </div>
  );
}

export function LoadingGif({ isLoading }: { isLoading: boolean }) {
  return isLoading ? (
    <div data-testid="loading-gif" className="fixed left-[50%] top-[50%]">
      <img src={loadingGif} className="rounded" />
    </div>
  ) : null;
}
