import {
  ReactElement,
  StrictMode,
  useContext,
  useEffect,
  useState,
} from "react";
import { CookiesProvider } from "react-cookie";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import CASBlock from "@components/CASBlock";
import Footer from "@components/Footer/Footer";
import UnverifiedBanner from "@components/Header/UnverifiedBanner";
import KeywordLimitModal from "@components/KeywordFilter/components/KeywordLimitModal";
import PackageVersionTag from "@components/PackageVersionTag/PackageVersionTag";
import PageGeneratedAtTag from "@components/PageGeneratedAtTag/PageGeneratedAtTag";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import useAllCustomHooks from "@hooks/useAllCustomHooks";
import useMyBtFollowedKeywordsInfo from "@hooks/useMyBtFollowedKeywordsInfo";
import { NeuronProvider } from "@sphtech/neuron";
import { RenderContext } from "@sphtech/web2-core/ssr";
import useMyBtStore from "@store/useMyBtStore";
import useOKTAUserStore from "@store/useOKTAUserStore";
import {
  CDP_EXIT_INTERVENTION_MODULE_ID,
  gtmId,
  ROUTES_TO_EXCLUDE_COMMON_FOOTER,
  VWO_SCRIPT,
} from "@util/constant";
import { neuronConfig } from "@util/neuron/constants";
import NeuronImplementation from "@util/neuron/NeuronImplementation";
import NewRelic from "@web2/providers/NewRelic";

// Order of imports will affect default margins on html elements
import "./index.css";
import "@styles/index.css";

// Previous implementation of helmetContext without sphtech/web2-core/ssr
// export const helmetContext: Record<string, never> | FilledContext = {};

export default function App(): ReactElement {
  const renderContext = useContext(RenderContext);
  useAllCustomHooks();

  // ScrollRestoration emits warnings during SSR as it uses useLayoutEffect
  // Don't render that during SSR.
  const [render, setRender] = useState(false);

  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  useMyBtFollowedKeywordsInfo(OKTAUserInfo?.usertype, OKTAUserInfo?.mysphw);
  const { isLimitModalShown, setIsLimitModalShown } = useMyBtStore();

  const { pathname } = useLocation();

  useEffect(() => {
    setRender(true);
  }, []);

  return (
    <StrictMode>
      <CookiesProvider>
        <NeuronProvider config={neuronConfig}>
          <NeuronImplementation />
          <HelmetProvider context={renderContext.helmet}>
            <Helmet>
              <link
                rel="preconnect"
                href="https://dev.visualwebsiteoptimizer.com"
              />
              <script type="text/javascript" id="vwoCode" async>
                {VWO_SCRIPT}
              </script>
            </Helmet>
            <NewRelic />

            <GTMProvider state={{ id: gtmId }}>
              {OKTAUserInfo !== null &&
              OKTAUserInfo?.reguserstatus === "unverified" ? (
                <UnverifiedBanner email={OKTAUserInfo?.loginid as string} />
              ) : null}

              <div
                className="flex min-h-screen w-screen max-w-full flex-col"
                id="background_container"
              >
                {/* Body */}
                <div
                  className="mx-auto flex w-full flex-grow flex-col items-center"
                  id="content-container"
                >
                  <Outlet />

                  {isLimitModalShown ? (
                    <KeywordLimitModal
                      onClose={() => {
                        setIsLimitModalShown(false);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </GTMProvider>

            {!ROUTES_TO_EXCLUDE_COMMON_FOOTER.includes(pathname) ? (
              <Footer />
            ) : null}
          </HelmetProvider>
        </NeuronProvider>
      </CookiesProvider>
      {/* ScrollRestoration emits warnings during client-side rendering */}
      {render ? <ScrollRestoration /> : null}
      <CASBlock cdpId={CDP_EXIT_INTERVENTION_MODULE_ID} />
      <PackageVersionTag />
      <PageGeneratedAtTag />
    </StrictMode>
  );
}
