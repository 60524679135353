import { Link } from "react-router-dom";
import type { ArticleCardProps } from "@components/ArticleCard/types";
import Kicker from "@components/ArticleMeta/Kicker";
import TitleLink from "@components/ArticleMeta/TitleLink";
import ArticleThumbnail from "@components/ArticleThumbnail/ArticleThumbnail";
import { RouteFactory } from "@src/app/routePaths";

import { extractMagazineData } from "./helpers/magazine";

export default function WealthMagazineArticle({
  id = "",
  articleData,
}: ArticleCardProps) {
  const { title, kicker, id: articleId = "", media } = articleData;
  const { magazineLink, magazineTitle } = extractMagazineData(articleData);

  const kickerValue = kicker?.fields?.[0]?.value;
  const componentId = id || `article_${articleId}`;
  const _media = media && "length" in media ? media.at(0) : media;
  const thumbnail = _media?.content;

  return (
    <div id={componentId} data-testid={componentId}>
      <div className="mb-2 font-poppins uppercase">
        <Link
          className="hover:underline"
          to={RouteFactory.section(magazineLink)}
          target="_blank"
          rel="noopener"
        >
          {magazineTitle}
        </Link>
      </div>
      <div className="bg-gray-150">
        <div className="flex border-b p-4">
          <div className="w-[40%]">
            {thumbnail ? <ArticleThumbnail thumbnail={thumbnail} /> : null}
          </div>
          <div className="w-[60%] pl-4">
            {kickerValue ? <Kicker name={kickerValue} /> : null}
            <div>
              {title ? (
                <TitleLink
                  rootClassName="!text-lg w-full font-semibold my-2"
                  title={title}
                  articleId={articleId}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
