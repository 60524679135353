import { Tag } from "@src/app/types/Cue";

/**
 * Get the href of keyword in keywords/uri format.
 * @param tag The tag object.
 * @returns {string} uri
 *
 */
export const getKeywordLink = (tag: Tag): string => {
  if (tag.uri === "") return "";
  if (tag.uri === null) return "";
  if (tag.uri.includes("/keywords/")) return tag.uri;
  if (tag.uri.includes("keywords/")) return `/${tag.uri}`;
  if (tag.uri.includes("main/"))
    return `/${tag.uri.replace("main/", "keywords/")}`;
  return `/keywords/${tag.uri}`;
};

/**
 * Verify if keyword is followed.
 * @param keywordToCheck The keyword to verify.
 * @param listOfFollowKeywords The list of followed keywords.
 * @returns true || false
 */
export const isKeywordFollowed = (
  keywordToCheck: Tag,
  listOfFollowKeywords: Tag[]
) => {
  return listOfFollowKeywords.some((tag) => tag.uri === keywordToCheck.uri);
};
