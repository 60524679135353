import { ReactElement } from "react";
import { ArticleDataObject } from "@app/types/Cue";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { BlockTitle } from "@blocks/Blocks";
import Advertisement from "@components/Advertisement/Advertisement";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import { useCategoriesFromPath } from "@components/Header/helpers";
import {
  getArticleBlurb,
  getKickerFromArticleDataObject,
  GoogleAdsSlotFactory,
} from "@util/helpers";

export type WealthWatchesProps = {
  articles: ArticleDataObject[];
};

export default function WealthWatches({
  articles,
}: WealthWatchesProps): ReactElement {
  const { parentCategory } = useCategoriesFromPath();

  const variant: CardVariations = {
    image: {
      position: "left",
      width: "w-1/2",
    },
    content: {
      width: "w-1/2",
      extraClass: "bg-gray-150 p-3",
    },
    kicker: {
      color: "text-gray-515",
    },
  };

  return (
    <Row data-testid="wealth-watches-component">
      <Column rootClassName="w-full">
        <div className="border-t border-black pt-3"></div>
      </Column>

      <Column rootClassName="pb-6 w-full lg:w-8/12">
        <BlockTitle
          text="WATCHES"
          link="/keywords/watches"
          rootClassName="mb-3"
        />
        <>
          {articles?.map((article) => {
            const { id, media, title, paywall, created } = article;

            return (
              <BasicCard
                key={id}
                id={id}
                slug={article.slug}
                media={media}
                kicker={getKickerFromArticleDataObject(article)}
                title={title}
                blurb={getArticleBlurb(article)}
                paywall={paywall?.contentAccess === "1"}
                variations={variant}
                created={created}
              />
            );
          })}
        </>
      </Column>

      <Column rootClassName="pb-6 w-full lg:w-4/12 flex">
        <Advertisement
          rootClassName="bg-stripes py-5"
          adUnitProps={{
            type: AdvertisementTypeEnum.IMU2,
            slot: GoogleAdsSlotFactory.imu2(parentCategory),
          }}
        />
      </Column>
    </Row>
  );
}
