import type { Params } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { Element, Storyline } from "@app/types/Cue";
import { ArticleDisplayType, KickerEnums } from "@app/types/enums";
import CompactViewMode from "@pages/Article/components/ArticleDisplay/ArticleDisplayCompact";
import InfographicsViewMode from "@pages/Article/components/ArticleDisplay/ArticleDisplayInfographics";
import LifestyleViewMode from "@pages/Article/components/ArticleDisplay/ArticleDisplayLifestyle";
import MainNewsDefaultViewMode from "@pages/Article/components/ArticleDisplay/ArticleDisplayMainNewsDefault";
import VerticalNewsDefaultViewMode from "@pages/Article/components/ArticleDisplay/ArticleDisplayVerticalsNewsDefault";
import AseanBanner from "@pages/Section/layouts/Verticals/AseanBusiness/components/AseanBanner";
import GlobalEnterpriseBanner from "@pages/Section/layouts/Verticals/GlobalEnterprise/components/GlobalEnterpriseBanner";
import SMEBanner from "@pages/Section/layouts/Verticals/SME/components/SMEBanner";
import { isEmpty } from "lodash-es";

import { ELEMENTS_TYPE_TO_EXCLUDE } from "./constants";

export const ArticleFactory = ({
  displayType = ArticleDisplayType.MainNewsDefault,
}: Params) => {
  switch (displayType) {
    case ArticleDisplayType.Compact:
      return CompactViewMode;

    case ArticleDisplayType.Infographics:
      return InfographicsViewMode;

    case ArticleDisplayType.Lifestyle:
      return LifestyleViewMode;

    case ArticleDisplayType.VerticalsNewsDefault:
      return VerticalNewsDefaultViewMode;

    case ArticleDisplayType.MainNewsDefault:
    default:
      return MainNewsDefaultViewMode;
  }
};

export const VerticalHeaderFactory = ({
  verticalPath,
}: {
  verticalPath: string;
}) => {
  switch (verticalPath) {
    case RouteFactory.globalEnterprise:
      return GlobalEnterpriseBanner;

    case RouteFactory.aseanBusiness:
      return AseanBanner;

    case RouteFactory.sgsme:
      return SMEBanner;
  }
};

/**
 * Checks if paywall should be displayed on Articles
 * Examples of articles shown on Article.test.tsx
 * @param {Object} props
 * @param {boolean} props.isGiftReceived If article was received as a gift
 * @param {string} props.variant "default" || "full"
 * @param {string} props.contentAccess "0" || "1"
 * @returns {boolean}
 */
export const checkDisplayPaywall = ({
  isGiftReceived,
  variant,
  contentAccess,
}: {
  isGiftReceived: boolean;
  variant: string | undefined;
  contentAccess: string;
}): boolean => {
  if (isGiftReceived || variant === "full") return false;
  return contentAccess === "1";
};

/**
 * Transpose the article elements and retrieve the annotations and elements values.
 * @param storyLine The storyline of the article.
 * @param elements The full list of elements in the article.
 * @param isSubscriber The user type.
 * @param isPremium The article content access.
 * @returns {Element[]}
 */
export const getResolvedArticleElements = (
  storyLine: Storyline = [],
  elements: Element[],
  isPremium: boolean = false,
  isSubscriber: boolean = false,
  isGifted: boolean = false,
  isPreview: boolean = false
): Element[] => {
  // Get the list of elements based on storyline
  const elementsWithAnnotationRef = getArticleElementsWithAnnotationReference(
    storyLine,
    elements
  );

  // Remove elements that are not needed
  const elementsWithoutExcludedType = elementsWithAnnotationRef.filter(
    ({ type }) => !ELEMENTS_TYPE_TO_EXCLUDE.includes(type.toString())
  );

  // Add reference to elements that has children
  const elementsWithListRef = elementsWithoutExcludedType.map((element) => {
    const reference = element.children
      ?.map((child) => elements.find((element) => child === element.id))
      .filter((element): element is Element => !!element);

    if (typeof reference === "undefined" || isEmpty(reference)) return element;

    const annotationWithRef = getAnnotationReference(reference, elements);

    return { ...element, reference: annotationWithRef };
  });

  // Logical gate to check if article will be displayed in full
  const fullList = isGifted || isSubscriber || !isPremium || isPreview;

  // Return full list of elements.
  if (fullList) return elementsWithListRef;

  // Return full list of elements.
  return elementsWithListRef.slice(0, 3);
};

/**
 * Retrieves the annotation reference for each element in the given array of elements.
 *
 * @param elements - The array of elements to retrieve the annotation reference for.
 * @param elementSource - The array of element sources to search for the annotation reference.
 * @returns An array of elements with their corresponding annotation references.
 */
const getAnnotationReference = (
  elements: Element[],
  elementSource: Element[]
): Element[] => {
  const annotationWithRef = elements.map((element) => {
    const fields = element.fields.map((field) => {
      const annotations = field?.annotations?.map((annotation) => {
        const element = elementSource.find((element) => {
          return element.id === annotation.value;
        });

        if (element) {
          return {
            ...annotation,
            reference: element,
          };
        }

        return annotation;
      });

      if (typeof annotations === "undefined" || isEmpty(annotations))
        return field;

      return { ...field, annotations };
    });

    return { ...element, fields };
  });

  return annotationWithRef;
};

/**
 * Retrieves the list of article elements with annotation references based on the storyline and elements.
 * @param {Storyline} storyLine The storyline of the article.
 * @param {Element[]} elements The list of all elements in the article.
 * @returns {Element[]} The list of article elements with annotation references.
 */
const getArticleElementsWithAnnotationReference = (
  storyLine: Storyline = [],
  elements: Element[]
): Element[] => {
  const articleElements: Element[] = storyLine
    .map((id) => elements.find((element) => element.id === id)) // Get the list of elements based on storyline
    .filter((element): element is Element => !!element); // Remove undefined elements

  const articleElementsWithAnnotationsRef = getAnnotationReference(
    articleElements,
    elements
  );

  return articleElementsWithAnnotationsRef;
};

/**
 * Check if it a branded content
 *
 * @param kicker
 */
export const getIsBrandedContent = (kicker: string) => {
  return (
    kicker.toUpperCase().replace(/\s/g, "") === KickerEnums.BRANDED_CONTENT
  );
};
