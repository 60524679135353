import { Link } from "react-router-dom";
import { getMimeTypeIcon } from "@app/util/helpers";
import useArticleAttachments from "@src/app/hooks/useArticleAttachments";
import cx from "classnames";

type ArticleAttachmentsProps = {
  rootClassName?: string;
  cueId: string;
};

export default function ArticleAttachmentsFromDrupal({
  rootClassName,
  cueId,
}: ArticleAttachmentsProps): React.ReactElement {
  const articleAttachments = useArticleAttachments(cueId);
  return (
    <>
      {cueId?.length ? (
        <div data-testid="article-attachments-component">
          {articleAttachments.map((attachment, index) => {
            const iconPath = getMimeTypeIcon(attachment.url);

            return (
              <div
                key={index}
                className={cx(
                  rootClassName,
                  "my-2 border-b border-gray-250 py-2"
                )}
              >
                {iconPath ? (
                  <span
                    className="inline-block h-4 w-4 bg-center bg-no-repeat align-middle"
                    style={{ backgroundImage: `url(${iconPath})` }}
                  ></span>
                ) : null}
                <Link
                  className="file pl-2 font-poppins text-base font-normal hover:!text-gray-850 hover:underline"
                  to={attachment.url}
                >
                  {attachment.name}
                </Link>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
}
