import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@src/app/components/AccessibleComponents/Button";
import loadQueryly from "@src/app/util/loadQueryly";

interface SearchTriggerProps {
  handleOnClose: () => void;
}

export default function SearchTrigger({ handleOnClose }: SearchTriggerProps) {
  const handleClose = () => {
    handleOnClose();
    loadQueryly().then((queryly) => {
      queryly.opensearch();
    });
  };

  return (
    <Button
      className="mx-auto flex h-9 w-full items-center justify-center gap-2 text-center"
      aria-label="Search"
      onPress={handleClose}
    >
      <FontAwesomeIcon icon={faMagnifyingGlass} />
      <p className="font-norma m-0 font-poppins text-[0.8125rem] tracking-[0.025rem] text-gray-850">
        Search
      </p>
    </Button>
  );
}
