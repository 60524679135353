/**
 * Company: SPHMedia
 * Description: Unverified banner that has a fixed position at the top of the screen
 */

import { ReactElement, useState } from "react";
import Button from "@src/app/components/AccessibleComponents/Button";
import { mySPHOpenResendVerificationMail } from "@src/app/util/helpers";
import cx from "classnames";

import Icon from "../Icon/Icon";

type UnverifiedBannerProps = {
  email: string;
};

/**
 * UnverifiedBanner
 * Description: Unverified banner that is displayed when a user is not verified
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function UnverifiedBanner({
  email = "",
}: UnverifiedBannerProps): ReactElement {
  const [visible, setVisible] = useState(true);

  const removeComponent = () => {
    setVisible((prev) => !prev);
  };

  return (
    <>
      {visible ? (
        <div className="mb-4 bg-gray-900 py-4 text-center text-white">
          <div className="grid grid-cols-12 place-content-center gap-3">
            <div className="col-span-11 flex flex-wrap gap-3 justify-self-center">
              <div className="flex flex-auto items-center">
                <p className="mb-0 px-2 font-poppins text-3xs lg:px-0">
                  Hello, your email is unverified. Please confirm
                  <strong>{email}</strong> for acess to all your SPH accounts.
                </p>
              </div>
              <div className="flex flex-auto items-center justify-center">
                <span id="sph_user_name" className="hidden">
                  {encodeURI(email)}
                </span>
                <Button
                  className={cx("font-poppins text-2xs font-bold underline")}
                  onPress={() => {
                    mySPHOpenResendVerificationMail();
                  }}
                >
                  Resend Verification Email
                </Button>
              </div>
            </div>
            <div className="flex h-full items-center justify-center place-self-end">
              <Button
                onPress={() => {
                  removeComponent();
                }}
              >
                <Icon type="close" size={30} />
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
