/**
 * Company: SPHMedia
 * Description: The vertical latest article list item
 */

import { ReactElement } from "react";
import type { ArticleCardProps } from "@components/ArticleCard/types";
import CreateTime from "@components/ArticleMeta/CreateTime";
import KeywordLinks from "@components/ArticleMeta/KeywordLinks";
import SectionLink from "@components/ArticleMeta/SectionLink";
import SubscriberFlag from "@components/ArticleMeta/SubscriberFlag";
import Summary from "@components/ArticleMeta/Summary";
import TitleLink from "@components/ArticleMeta/TitleLink";
import UpdateTime from "@components/ArticleMeta/UpdateTime";
import ArticleThumbnail from "@components/ArticleThumbnail/ArticleThumbnail";
import { faCircle, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VERTICAL_COLOUR_VARIANTS, VerticalName } from "@util/constant";
import { getArticleBlurb, getDefaultImage } from "@util/helpers";
import cx from "classnames";

export interface VerticalLatestProps extends ArticleCardProps {
  sectionLinkRootClassName?: string;
  verticalName: VerticalName;
  kicker?: string;
}

/**
 * VerticalLatest
 * @description The vertical latest article list item
 * @param {VerticalLatestProps} props
 * @returns {ReactElement}
 */
export default function VerticalLatest({
  id = "",
  rootClassName = "",
  sectionLinkRootClassName = "font-poppins font-normal text-[12px] tracking-[1px] text-[--digital-red]",
  verticalName,
  articleData,
  kicker,
}: VerticalLatestProps): ReactElement {
  const {
    id: articleId = "",
    created,
    updated,
    edited,
    title,
    sections,
    paywall,
    tags,
    media,
    displaySetting,
    slug = "",
  } = articleData;
  const componentId = id || `article_${articleId}`;
  const firstMedia = media?.at(0);
  const sectionUniqueName = sections?.at(0)?.uniqueName ?? "";
  const blurb = getArticleBlurb(articleData);
  const thumbnail = firstMedia?.content
    ? firstMedia.content
    : {
        url: getDefaultImage(sectionUniqueName),
        alt: title,
      };
  const isVideo =
    firstMedia?.content?.type === "brightcoveVideo" ||
    firstMedia?.content?.type === "youtubeVideo";
  return (
    <div
      id={componentId}
      data-testid={componentId}
      className={cx("flex flex-row gap-x-4", rootClassName)}
    >
      <div className="flex w-full-120px flex-col">
        {sections && sections.length > 0 ? (
          <SectionLink
            rootClassName={cx(
              sectionLinkRootClassName,
              VERTICAL_COLOUR_VARIANTS[verticalName].textStyle,
              VERTICAL_COLOUR_VARIANTS[verticalName].linkStyle
            )}
            sectionName={sections.at(0)?.name}
            sectionUniqueName={sectionUniqueName}
          />
        ) : null}

        {kicker ? (
          <div
            className="break-words font-poppins text-4xs font-light text-gray-515"
            data-testid="vertical-latest-kicker-component"
          >
            {kicker}
          </div>
        ) : null}

        <TitleLink
          rootClassName={cx(
            "font-poppins font-semibold text-xs !leading-normal text-gray-850",
            VERTICAL_COLOUR_VARIANTS[verticalName].hoverStyle
          )}
          articleId={articleId}
          title={displaySetting?.displayHeadline || title}
          slug={slug}
        />

        {paywall && paywall.contentAccess === "1" ? (
          <SubscriberFlag rootClassName="font-poppins text-gray-850 text-4xs" />
        ) : null}

        {blurb ? (
          <Summary
            rootClassName="font-poppins text-xs font-normal !leading-normal text-gray-850"
            summary={blurb}
          />
        ) : null}
        <div
          className={cx(
            "mt-2 items-center gap-x-4 gap-y-1",
            "lg:flex lg:flex-row lg:flex-wrap"
          )}
        >
          {created ? (
            <div className="flex flex-row gap-x-2">
              <CreateTime created={created} />
              {displaySetting?.hasUpdatedTimeDisplayed ? (
                <UpdateTime
                  updated={updated}
                  edited={edited}
                  rootClassName="text-red"
                />
              ) : null}
            </div>
          ) : null}
          {tags ? (
            <div>
              <KeywordLinks tags={tags} rootClassName={cx("mt-2", "lg:mt-0")} />
            </div>
          ) : null}
        </div>
      </div>
      <div className="relative flex max-h-[80px]">
        <ArticleThumbnail
          rootClassName="w-120px"
          thumbnail={thumbnail}
          orientation="landscape"
          imgRootClassName="aspect-ratio-3x2 w-full"
        />
        {isVideo ? (
          <div className="fa-stack absolute bottom-6 left-10">
            <FontAwesomeIcon icon={faCircle} inverse className="fa-stack-2x" />
            <FontAwesomeIcon icon={faPlay} className="fa-stack-1x" />
          </div>
        ) : null}
      </div>
    </div>
  );
}
