import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import useOKTAUserStore from "@store/useOKTAUserStore";

import { getVisitorCat } from "./helper";

type GADataProps = {
  articleid?: string;
  cue_articleid?: string;
  at?: string;
  author?: string;
  chapter1?: string;
  contentcat?: 1 | 2;
  contenttype?: "" | "1" | "2" | "3" | undefined;
  keyword?: string;
  level2?: string;
  pagination?: number;
  // permutive_id?: string;
  pubdate?: string;
  story_threads?: string;
  title?: string;
  visitorcat?: 1 | 2 | undefined;
  termid?: string;
  product_flag?: string;
  user_keyword?: string;
  adblocker?: 0 | 1;
  cd_version?: "" | "bt2" | undefined;
};

/**
 * GAData
 * Description: This component renders the _data layer_ for GA to consume.
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GAData(props: GADataProps): ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const {
    articleid = "",
    at = OKTAUserInfo?.mysphw || "", // mysphw cookie
    author = "", // Comma separated author name
    chapter1 = "", // Section child category name.
    contentcat = 1, // Paid (2) or free (1)
    contenttype = "", // Index/listing = "", article = 1 photo = 2 video = 3
    keyword = "", // Comma separated keyword names
    // if in article, level2 = main category parent or main category, main category is the first category
    // if breakingnews, level2 = breaking news
    level2 = "",
    pagination = 1,
    // permutive_id = "", // window.localStorage.getItem("permutive-id") || "",
    pubdate = "", // Post date of article otherwise ""
    story_threads = "", // Comma separated story thread name
    title = "", // ie I_am_the_article_title, Section_name_Index, Breaking_News_Index
    visitorcat = getVisitorCat(
      OKTAUserInfo?.usertype,
      OKTAUserInfo?.reguserstatus,
      OKTAUserInfo?.service_type
    ),
    termid = "", // Section term id
    product_flag = "(not set)",
    user_keyword = "(not set)",
    adblocker = 0,
    cd_version = "bt2", // to determine if from pv is from bt1 or bt2, no value is bt1
  } = props;

  return (
    <Helmet>
      <script id={`ga_data_layer_${articleid}`}>
        {`
var _data = {};
_data = {
  "articleid"      : "${articleid}",
  "cue_articleid"  : "${articleid}",
  "at"             : "${at}",
  "author"         : "${author}",
  "chapter1"       : "${chapter1}",
  "contentcat"     : "${contentcat}",
  "contenttype"    : "${contenttype}",
  "keyword"        : "${keyword}",
  "level2"         : "${level2}",
  "pagination"     : "${pagination}",
  "permutive_id"   : window.localStorage.getItem("permutive-id") || "",
  "pubdate"        : "${pubdate}",
  "story_threads"  : "${story_threads}",
  "title"          : "${title}",
  "visitorcat"     : "${visitorcat}",
  "termid"         : "${termid}",
  "product_flag"   : "${product_flag}",
  "user_keyword"   : "${user_keyword}",
  "adblocker"      : ${adblocker},
  "cd_version"     : "${cd_version}",
};

var isArticlePage = false;
if (typeof _data != "undefined" && typeof _data.articleid != "undefined" && _data.articleid != "") {
  isArticlePage = true;
}
          `}
      </script>
    </Helmet>
  );
}
