import newsPostIcon from "@assets/logo-newspost.svg";
import { cn } from "@util/helpers";

type ArticlePurchaseLinkProps = {
  title: string;
  publishedDate: string;
  rootTextClassName?: string;
};

export default function ArticlePurchaseLink({
  title,
  publishedDate,
  rootTextClassName,
}: ArticlePurchaseLinkProps): React.ReactElement {
  const date = encodeURIComponent(publishedDate);
  const encodedTitle = encodeURIComponent(title);

  return (
    <section
      className="mx-auto my-4 w-full"
      data-testid="article-purchase-link-component"
    >
      <a
        className={cn(
          "flex items-center justify-between font-poppins font-semibold hover:underline",
          rootTextClassName
        )}
        href={`https://newspost.newslink.sg/?publication=BT&date=${date}&title=${encodedTitle}`}
        target="_blank"
        rel="noreferrer"
      >
        <span>Purchase this article</span>
        <img src={newsPostIcon} alt="newspaper icon" />
      </a>
    </section>
  );
}
