export enum SocialMediaType {
  facebook = "facebook",
  telegram = "telegram",
  email = "email",
  linkedin = "linkedin",
  twitter = "twitter",
  whatsapp = "whatsapp",
  instagram = "instagram",
}

export enum IconName {
  facebook = "facebook",
  telegram = "telegram",
  email = "email",
  linkedin = "linkedin",
  twitter = "twitter",
  whatsapp = "whatsapp",
  instagram = "instagram",
}

export type ButtonProps = {
  sizeClass?: string;
  whichSocialMedia?: SocialMediaType;
  shareUrl?: string;
  iconToDisplay?: IconName;
  actionType?: ActionType;
  imgAlt?: string;
  title?: string;
};

export enum ActionType {
  buttonLink = "buttonLink",
  share = "share",
}
