import { useState } from "react";
import Slider, { Settings } from "react-slick";
import { BlockTitle } from "@blocks/Blocks";
import { NEWSLETTER_PAGE_LINK } from "@components/Newsletter/constants";
import HomepageCarouselNewsletterSlide from "@components/Newsletter/HomepageCarouselNewsletter/HomepageCarouselNewsletterSlide";
import NewsletterSubscriptionPanel from "@components/Newsletter/NewsletterSubscriptionPanel";
import {
  BT_NEWSLETTERS,
  Newsletter,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import SliderArrow from "@components/Slider/components/SliderArrow";
import { useWindowSize } from "@hooks/useWindowSize";
import cx from "classnames";

export type HomepageCarouselNewsletterProps = {
  testId?: string;
  rootClassName?: string;
  blockTitle?: string;
  showSeeMore?: boolean;
  newsletterToSkip?: NEWSLETTER_TYPE;
};

export default function HomepageCarouselNewsletter({
  testId,
  rootClassName,
  newsletterToSkip,
}: HomepageCarouselNewsletterProps): React.ReactElement {
  const { isScreenLG } = useWindowSize();
  const [email, setEmail] = useState("");
  const newsletters = isScreenLG ? BT_NEWSLETTERS : BT_NEWSLETTERS.slice(0, 4);
  const SliderComponent =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    typeof window === "undefined" ? Slider.default : Slider;

  const [selectedNewsletters, setSelectedNewsletters] = useState<Newsletter[]>(
    []
  );

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 4,
    className: cx(
      "newsletter-homepage-carousel grid grid-cols-1 gap-6",
      "sm:grid-cols-2",
      "lg:block lg:-mr-5"
    ),
    arrows: true,
    prevArrow: (
      <SliderArrow
        direction="left"
        rootClassName="absolute bottom-[calc(100%+.7rem)] right-[4.5rem]"
        opacityWhenDisabled="opacity-30"
        prevArrowClass=""
      />
    ),
    nextArrow: (
      <SliderArrow
        direction="right"
        rootClassName="absolute bottom-[calc(100%+.7rem)] right-6"
        opacityWhenDisabled="opacity-30"
        nextArrowClass=""
      />
    ),
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 991,
        settings: "unslick",
      },
    ],
  };

  const handleSelectedNewsletter = (
    action: "remove" | "add" | "reset",
    newsletter?: Newsletter
  ) => {
    // Add newsletter to the selected list.
    if (action === "add" && newsletter) {
      setSelectedNewsletters((selectedNewsletters) => [
        ...selectedNewsletters,
        newsletter,
      ]);
    }

    // Remove newsletter to the selected list.
    if (action === "remove" && newsletter) {
      setSelectedNewsletters((selectedNewsletters) =>
        selectedNewsletters.filter(
          (selectedNewsletter) => selectedNewsletter.type !== newsletter.type
        )
      );
    }

    // Remove newsletter to the selected list.
    if (action === "reset") {
      setSelectedNewsletters([]);
    }
  };

  return (
    <div className={rootClassName} data-testid={testId}>
      <BlockTitle
        link={NEWSLETTER_PAGE_LINK}
        text="OUR NEWSLETTERS"
        rootClassName="mb-3"
      />

      <SliderComponent {...settings}>
        {newsletters
          .filter((newsletter) => newsletter.type !== newsletterToSkip)
          .map((newsletter) => (
            <HomepageCarouselNewsletterSlide
              key={newsletter.type}
              newsletter={newsletter}
              rootClassName="lg:pr-6 text-gray-850"
              handleSelectedNewsletter={handleSelectedNewsletter}
              subscribed={selectedNewsletters.some(
                (selectedNewsletter) =>
                  selectedNewsletter.type === newsletter.type
              )}
              premiumOnly={newsletter?.premiumOnly}
            />
          ))}
      </SliderComponent>

      <>
        {selectedNewsletters.length > 0 ? (
          <NewsletterSubscriptionPanel
            selectedNewsletters={selectedNewsletters}
            handleSelectedNewsletter={handleSelectedNewsletter}
            email={email}
            setEmail={setEmail}
          />
        ) : null}
      </>
    </div>
  );
}
