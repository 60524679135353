import { TestIdClassNameProps } from "@app/types/Common";
import { ArticleDataObject, Tag } from "@src/app/types/Cue";
import {
  getKickerFromArticleDataObject,
  isArticleUpdated,
} from "@src/app/util/helpers";
import cx from "classnames";

import BasicCard from "../ArticleCard/BasicCard";
import { CardVariations } from "../ArticleCard/types";
import KeywordFilter from "../KeywordFilter";

export type MyBTCarouselSlideProps = TestIdClassNameProps & {
  articles: ArticleDataObject[];
  tag: Tag;
  disablePaywall?: boolean;
};

export default function MyBTCarouselSlide({
  testId,
  rootClassName,
  articles,
  tag,
  disablePaywall = false,
}: MyBTCarouselSlideProps): React.ReactElement {
  return (
    <div className={cx(rootClassName, "h-full")} data-testid={testId}>
      <div className="h-full bg-gray-150 p-4">
        {tag.uri ? (
          <KeywordFilter
            tag={tag}
            tagClassName="!text-xs !font-normal"
            rootClassName="border border-gray-850 rounded-full px-2 py-1 mb-4"
            iconClassName="bg-black text-white rounded-full w-[1em] h-[1em] p-1"
            disableTooltip
            disableBorder
            isLinkenabled={false}
            testId={testId ? `${testId}-mybt-keyword-button` : undefined}
          />
        ) : null}

        <div className="stories">
          {articles?.map((article, index) => {
            const {
              id,
              title,
              slug,
              media,
              paywall,
              edited,
              updated,
              sections,
              displaySetting,
              kicker,
            } = article;
            const mainSection = sections?.at(0);

            const variant: CardVariations = {
              ...(index === 0
                ? {
                    image: {
                      width: "w-3/10",
                      position: "left",
                    },
                    content: {
                      width: "w-7/10",
                      extraClass: "pl-4",
                    },
                  }
                : null),
              title: {
                color: "text-gray-850",
                size: "text-xs",
                extraClass: "font-poppins font-medium",
              },
            };

            return (
              <BasicCard
                key={`${id}_${index}`}
                id={id}
                title={displaySetting?.displayHeadline || title}
                media={index === 0 ? media : undefined}
                slug={slug}
                paywall={
                  !disablePaywall ? paywall?.contentAccess === "1" : false
                }
                variations={variant}
                rootClassName={cx("story border-gray-175", {
                  "pt-4": index > 0,
                  "border-b pb-4": index < articles.length - 1,
                })}
                kicker={
                  kicker?.fields.at(0)?.value ||
                  getKickerFromArticleDataObject(article)
                }
                edited={isArticleUpdated(updated, edited) ? edited : undefined}
                hasUpdatedTimestampDisplay={
                  displaySetting?.hasUpdatedTimeDisplayed
                }
                defaultImage={
                  index === 0 && mainSection
                    ? { directoryName: mainSection?.uniqueName }
                    : undefined
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
