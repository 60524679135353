import { RouteFactory } from "@app/routePaths";
import { AdvertisementTypeEnum } from "@app/types/enums";
import internationalGlobalLogo from "@assets/verticals/international-global-logo-blue.svg";
import SCLogo from "@assets/verticals/standard-chartered-logo.svg";
import { AdTargetingType } from "@components/Advertisement/AdUnit";
import Advertisement from "@components/Advertisement/Advertisement";
import SectionMasthead from "@components/SectionMasthead/SectionMasthead";
import { GoogleAdsSlotFactory } from "@util/helpers";

export default function GlobalEnterpriseBanner({
  slotTargettings = [],
}: {
  slotTargettings?: AdTargetingType[];
}): React.ReactElement {
  return (
    <>
      <div className="flex w-full flex-wrap items-center justify-center gap-2 pb-2 md:justify-between">
        <SectionMasthead
          link={RouteFactory.globalEnterprise}
          masthead={{
            alt: "Global Enterprise logo",
            src: internationalGlobalLogo,
            width: 150,
            height: 50,
          }}
        />
        <div className="block h-[0.0625rem] w-full bg-gray-850 md:hidden "></div>
        <div className="flex items-center justify-end gap-x-4">
          <span className="font-poppins text-4xs font-light">
            BROUGHT TO YOU BY
          </span>
          <img src={SCLogo} alt="UOB logo" className="w-[5rem]" width="90" />
        </div>
      </div>
      <div className="mb-5 mt-1 hidden min-h-[0.0625rem] w-full bg-gray-850 md:block "></div>

      <Advertisement
        rootClassName="pb-6"
        adUnitProps={{
          slot: GoogleAdsSlotFactory.lb1(
            RouteFactory.globalEnterprise.replace("/", "")
          ),
          type: AdvertisementTypeEnum.LB1,
          slotTargettings: slotTargettings,
          adsClassName: "min-h-[90px]",
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.CATFISH,
          slot: GoogleAdsSlotFactory.catfish(
            RouteFactory.globalEnterprise.replace("/", "")
          ),
          slotTargettings: slotTargettings,
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </>
  );
}
