import { Element } from "@app/types/Cue";
import { Name, Type } from "@app/types/enums";
import { useTextRangesWithAnnotations } from "@hooks/useTextRangesWithAnnotations";

export type ParagraphProps = {
  className?: string;
  element: Element;
};

export default function Paragraph({
  className,
  element,
}: ParagraphProps): React.ReactElement {
  const paragraph = element.fields.find((field) => {
    return field.name === Name.Paragraph;
  });

  const fieldValue = paragraph?.value || "";
  const annotations = paragraph?.annotations || [];

  const { componentToRender } = useTextRangesWithAnnotations(
    fieldValue,
    annotations
  );

  return (
    <p
      className={className}
      data-story-element={Type.Paragraph}
      data-testid="paragraph-component"
    >
      {componentToRender}
    </p>
  );
}
