import { ContributorProfile, Profile } from "@app/types/Cue";
import bylineDefaultImage from "@assets/defaultImages/byline/default.png";
import cx from "classnames";

type BylineImageProps = {
  rootClassName?: string;
  profile?: Profile | ContributorProfile;
  width?: number;
  height?: number;
};
export default function BylineImage({
  rootClassName,
  profile,
  width = 65,
  height = 65,
}: BylineImageProps): React.ReactElement {
  const byline_image =
    profile?.content.headshotImage.at(0)?.content.fields["square_320-caas"]
      ?.url || bylineDefaultImage;

  return (
    <img
      className={cx(rootClassName, "rounded-full")}
      src={byline_image}
      alt={profile?.content.fields.name}
      width={width}
      height={height}
    />
  );
}
