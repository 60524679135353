import { ArticleDataObject } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import {
  getKickerFromArticleDataObject,
  parseStoryThread,
} from "@util/helpers";
import cx from "classnames";

export default function HomepageLifestyleStories({
  article,
  disablePaywall = false,
}: {
  article: ArticleDataObject;
  disablePaywall?: boolean;
}): React.ReactElement {
  const section = article.sections?.at(0);
  const variant: CardVariations = {
    image: {
      position: "left",
      width: "w-120px lg:w-1/3",
    },
    content: {
      width: "w-full-120px lg:w-2/3",
      extraClass: "pl-4 text-white",
      updatedTimeColor: "!text-white",
    },
    title: {
      color: "text-white",
      size: "text-base",
    },
    kicker: {
      color: "text-gray-515",
      extraClass: "uppercase",
    },
  };
  const storyThread = parseStoryThread(article.others?.storyThread);

  return (
    <div className={cx("story py-3")}>
      <BasicCard
        id={article.id}
        slug={article.slug}
        title={article.displaySetting?.displayHeadline || article.title}
        kicker={
          article.kicker?.fields?.[0]?.value ||
          getKickerFromArticleDataObject(article)
        }
        section={section}
        storyThread={storyThread}
        paywall={
          !disablePaywall ? article.paywall?.contentAccess === "1" : false
        }
        variations={variant}
        media={article.media}
        edited={article.edited}
        hasUpdatedTimestampDisplay={
          article.displaySetting?.hasUpdatedTimeDisplayed
        }
        defaultImage={{
          directoryName: section?.uniqueName ?? "",
        }}
      />
    </div>
  );
}
