import { Link, useLocation } from "react-router-dom";
import { subscribeCTALink } from "@components/Footer/constant";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn, gaEventTracker } from "@util/helpers";
import cx from "classnames";

type SubscribeTriggerProps = {
  title?: string;
  rootClassName?: string;
  displayArrow?: boolean;
};

export default function SubscribeTrigger({
  title = "Subscribe",
  rootClassName,
  displayArrow = false,
}: SubscribeTriggerProps): React.ReactElement {
  const location = useLocation();

  const handleClick = () => {
    gaEventTracker("subscribe article header", "click", location.pathname);
  };

  return (
    <Link
      to={subscribeCTALink.header}
      target="_blank"
      rel="noopener"
      className={cn(
        "group inline-flex h-[37px] cursor-pointer items-center justify-center rounded-full bg-orange-300 px-6 font-poppins font-semibold leading-none tracking-tightest md:text-white ",
        "visited:text-white",
        "text-2xs",
        rootClassName
      )}
      data-testid="subscribe-trigger"
      onClick={handleClick}
    >
      <span
        className={cx("opacity-100 duration-300", "group-hover:opacity-75")}
      >
        {title}
      </span>

      {displayArrow ? (
        <span>
          <FontAwesomeIcon
            icon={faArrowRight}
            size="sm"
            className="inline-block pl-2"
          />
        </span>
      ) : null}
    </Link>
  );
}
