import cx from "classnames";

export type ColumnProps = {
  rootClassName?: string;
  children?: React.ReactElement | React.ReactElement[];
  id?: string;
};

export default function Column(props: ColumnProps): React.ReactElement {
  const { rootClassName, children, ...rest } = props;

  return (
    <div
      data-component="component-column"
      className={cx(rootClassName, "px-3")}
      {...rest}
    >
      {children}
    </div>
  );
}
