import { useState } from "react";
import whatsappQr from "@assets/bt-whatsapp-web.png";
import { gaEventTracker } from "@util/helpers";

import WhatsappQRModal from "./components/WhatsappQRModal";

export default function WhatsappQRSkybox(): React.ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClick = () => {
    setIsModalOpen(true);
    gaEventTracker("bt on whatsapp", "visible", "/");
  };

  return (
    <>
      <div
        className="relative flex cursor-pointer pr-4"
        data-testid="whatsapp-qr-skybox-component"
        onClick={handleClick}
      >
        <div className="w-[120px] md:w-1/3">
          <div className="flex aspect-square items-center justify-center">
            <img
              src={whatsappQr}
              width={200}
              height={200}
              alt="BT is now on WhatsApp"
            />
          </div>
        </div>

        <div className="pl-4 md:w-2/3">
          <div className="font-poppins text-4xs font-light uppercase tracking-[1px]">
            NEW
          </div>

          <h2 className="hover:none my-1 font-lct text-base font-bold text-gray-750">
            BT is now on WhatsApp!
          </h2>
        </div>
      </div>

      <WhatsappQRModal {...{ isModalOpen, setIsModalOpen }} />
    </>
  );
}
