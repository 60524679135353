import { Link } from "react-router-dom";
import IconAppleSvg from "@assets/podcasts_banner/svg/icon-apple.svg";
import IconChannelSvg from "@assets/podcasts_banner/svg/icon-bt.svg";
import IconSpotifySvg from "@assets/podcasts_banner/svg/icon-spotify.svg";
import Banner from "@components/Banner/Banner";
import { PodcastKeywordData } from "@pages/Section/layouts/PodcastsPage/util/constants";
import cx from "classnames";

export default function PodcastsBanner({
  label,
  spotify_link,
  apple_link,
  channel_link,
  description,
  thumbnailSrc,
}: PodcastKeywordData): React.ReactElement {
  return (
    <Banner rootClassName="w-full bg-gray-250 py-6 px-3">
      <div className={cx("flex flex-col items-center gap-3", "lg:flex-row")}>
        <img
          src={thumbnailSrc}
          alt="UOB logo"
          width="120"
          height="120"
          aria-label={label}
          className="aspect-square"
        />

        <div className="d-flex flex-column justify-content-center">
          <h5
            className={cx(
              "mb-4 text-center font-public-sans text-base font-normal",
              "lg:text-start"
            )}
          >
            {description}
          </h5>

          <div
            className={cx(
              "flex justify-center gap-4 text-center",
              "lg:justify-start"
            )}
          >
            <Link
              to={channel_link}
              target="_blank"
              rel="noreferrer"
              aria-label="channel link"
            >
              <img
                className="m-auto"
                src={IconChannelSvg}
                width="25"
                alt="channel link logo"
                aria-label="channel link logo"
              />

              <span className="font-poppins text-[8px] font-medium">
                Channel
              </span>
            </Link>

            <Link
              to={apple_link}
              target="_blank"
              rel="noreferrer"
              aria-label="apple link"
            >
              <img
                className="m-auto"
                src={IconAppleSvg}
                width="25"
                alt="apple link logo"
                aria-label="apple link logo"
              />

              <span className="font-poppins text-[8px] font-medium">Apple</span>
            </Link>

            <Link
              to={spotify_link}
              target="_blank"
              rel="noreferrer"
              aria-label="spotify link"
            >
              <img
                className="m-auto"
                src={IconSpotifySvg}
                width="25"
                alt="spotify link logo"
                aria-label="spotify link logo"
              />

              <span className="font-poppins text-[8px] font-medium">
                Spotify
              </span>
            </Link>
          </div>
        </div>
      </div>
    </Banner>
  );
}
