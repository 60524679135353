import { Link } from "react-router-dom";
import { ContributorProfile, Profile } from "@app/types/Cue";
import { getProfilesAlternativePath } from "@pages/Author/utils/helpers";
import { cn } from "@util/helpers";

export type BylineNameProps = {
  profile: Profile | ContributorProfile;
  separator: "," | "&" | "";
  rootClassName?: string;
  linkClassName?: string;
};

export default function BylineName({
  profile,
  separator,
  rootClassName,
  linkClassName,
}: BylineNameProps): React.ReactElement {
  const authorName = profile.content.fields.name;
  const alternativePath = getProfilesAlternativePath(
    profile.content.urlPath?.replace("/authors/", "")
  );
  const urlPath = alternativePath || profile.content.urlPath;

  return (
    <h4 className={cn(rootClassName)} data-testid="byline-name-component">
      {urlPath ? (
        <Link
          to={urlPath}
          className={cn(
            "word-break text-base !text-gray-850 hover:underline",
            linkClassName
          )}
          target="_blank"
          reloadDocument
        >
          {authorName} {separator}
        </Link>
      ) : (
        <>
          {authorName} {separator}
        </>
      )}
    </h4>
  );
}
