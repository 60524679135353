import { Link } from "react-router-dom";
import whiteBTLogo from "@assets/bt-logo-white-mobile.svg";
import whatsappQR from "@assets/img-whatsapp-qr-v3.svg";
import Button from "@components/AccessibleComponents/Button";
import Icon from "@components/Icon/Icon";
import Modal from "@components/Modal/Modal";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { whatsappChannelUrl } from "@util/constant";
import { cn, gaEventTracker } from "@util/helpers";

type WhatsappQRModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function WhatsappQRModal({
  isModalOpen,
  setIsModalOpen,
}: WhatsappQRModalProps): React.ReactElement {
  const className = "bg-gray-850 w-[21rem] md:w-[41rem] rounded-t-none";

  const handleClick = () => {
    gaEventTracker("bt on whatsapp", "click", whatsappChannelUrl);
  };

  return (
    <Modal
      {...{ isModalOpen, setIsModalOpen, dialogPanelClassNames: className }}
    >
      <div className="px-11 py-8 text-white xs:px-[3.75rem] lg:px-24 lg:py-10">
        <img
          src={whiteBTLogo}
          width={175}
          height={17}
          className="mx-auto"
          alt="BT Logo"
        />

        <h1 className="my-8 font-lct text-8xl font-normal">
          Breaking news and top stories, delivered right to your phone.
        </h1>

        <div className="lg:flex lg:flex-wrap lg:divide-x">
          <div className="lg:w-1/2 lg:pr-8">
            <p className="mb-3 hidden font-poppins text-xs lg:block">
              Click the button
            </p>

            <Link
              to={whatsappChannelUrl}
              target="_blank"
              className="mx-auto mb-8 inline-block rounded-lg bg-white p-3 lg:mb-0 lg:px-8"
              onClick={handleClick}
            >
              <span className="flex items-center">
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className="align-middle text-gray-850"
                  size="xl"
                />

                <span className="ml-2 whitespace-nowrap align-baseline font-poppins text-xs font-bold text-gray-850 lg:text-4xs">
                  BT on WhatsApp
                </span>
              </span>
            </Link>
          </div>

          <div className="hidden w-1/2 border-white border-opacity-15 pl-8 lg:block">
            <p className="mb-3 font-poppins text-xs">or scan the QR code</p>

            <img
              src={whatsappQR}
              alt="QR code"
              className="mx-auto"
              width={200}
              height={200}
            />
          </div>
        </div>

        <Button
          className={cn(
            "aspect-square rounded-full border border-white p-4 opacity-100 transition-opacity hover:opacity-75",
            "lg:absolute lg:right-5 lg:top-5 lg:border-none lg:p-0"
          )}
          onPress={() => {
            setIsModalOpen(false);
          }}
        >
          <Icon type="close" size={20} />
        </Button>
      </div>
    </Modal>
  );
}
